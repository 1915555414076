import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
  typography: {
    fontFamily:
      "'Afacad Flux', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",

    // Global base font size
    fontSize: 16, // This will increase the base font size across the app

    // Specific adjustments
    h1: {
      fontSize: "3rem", // Customize h1 font size
    },
    h2: {
      fontSize: "2.5rem", // Customize h2 font size
    },
    body1: {
      fontSize: "1.125rem", // General body text
    },
    body2: {
      fontSize: "1rem", // Secondary body text
    },
    button: {
      fontSize: "1rem", // Increase button font size
    },
  },

  // Ensure consistent typography in table components
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem", // Set the font size for table cells (matches body text size)
          fontFamily:
            "'Afacad Flux', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: "1rem", // Set the font size for table headers
          fontFamily:
            "'Afacad Flux', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <ToastContainer className={"no"} />
      <App />
    </AuthProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
