import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import courseicon from "../../assets/course.png";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Heading from "../../components/ui";

const Courses = () => {
  const { currentUser } = useAuth();
  const [Courses, setCourses] = useState([]);

  const getAllCourses = async () => {
    try {
      const response = await axiosInstance.post("/my_courses", {
        id: currentUser.id,
      });
      setCourses(response.data.courseDetails);
    } catch (error) {
      console.log("Error fetching group courses: ", error.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllCourses();
    }
  }, [currentUser]);
  return (
    <>
      <Heading
        extraClasses="text-left items-left"
        text={"Your Group Courses"}
      />
      <div className="w-full flex flex-col  justify-start items-center">
        <div className="w-[100%] flex flex-col justify-center items-start">
          <h1 className="text-[#23436d] font-bold text-[32px] mt-5 mb-5">
            All Courses
          </h1>
          {/* <div className="w-full grid grid-cols-4 justify-center items-center flex-wrap">
            {Courses &&
              Courses.map((course, index) => (
                <Link
                  key={index}
                  to={`/groupleader_dashboard/getting_started?course_id=${course.id}`}
                >
                  <CourseCard data={course} />
                </Link>
              ))}
          </div> */}
          <Grid container spacing={2}>
            {Courses?.map((course, index) => (
              <Grid
                item
                xs={12} // 1 card for extra small screens (below 600px)
                sm={12} // 1 card for small screens (600px - 800px)
                md={6} // 2 cards for medium screens (800px - 1200px)
                lg={4} // 3 cards for large screens (1200px - 1700px)
                xl={3} // 4 cards for extra-large screens (1700px and above)
                key={index}
              >
                <Link
                  key={index}
                  to={`/groupleader_dashboard/getting_started?course_id=${course.id}`}
                >
                  <CourseCard data={course} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

// export const CourseCard = ({ data }) => {
//   return (
//     <>
//       <div className="w-full bg-white border border-gray-200 rounded-lg shadow">
//         <img
//           className="rounded-t-lg max-h-[236px] min-h-[235px] cursor-not-allowed w-full"
//           src={
//             data.featuredImage
//               ? `${process.env.REACT_APP_BASE_URL}/${data.featuredImage}`
//               : courseicon
//           }
//           alt=""
//         />
//         <div className="p-5">
//           <h5 className="mb-2 text-[20px] truncate font-bold tracking-tight text-gray-900">
//             {data.title}
//           </h5>
//           <p className="mb-3 font-normal text-gray-900 truncate">
//             {data.description}
//           </p>
//         </div>
//       </div>
//     </>
//   );
// };
export const CourseCard = ({ data }) => {
  // Find applicable discount for the course

  const truncateDescription = (description, maxLength) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow flex flex-col h-full">
      {/* Course Icon */}
      <img
        className="rounded-t-lg no-round w-full h-[200px] object-cover"
        src={
          data.featuredImage
            ? `${process.env.REACT_APP_BASE_URL}/${data.featuredImage}`
            : courseicon
        }
        alt="Course Icon"
      />

      {/* Course Info */}
      <div className="p-5 flex-1 flex flex-col justify-between">
        <div>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className="truncate"
            style={{
              maxHeight: "2.6em",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data.title}
          </Typography>

          {/* Truncated Description */}
          <Typography
            variant="body2"
            color="textSecondary"
            className="mb-4 line-clamp-3"
            style={{
              maxHeight: "4.5em", // Allows for 3 lines of text
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {truncateDescription(data.description, 100)}
          </Typography>

          {/* {discount && (
						  <Typography variant="h6" color="error" className="mb-4 mt-4">
							  {discount.percentage}% off
						  </Typography>
					  )} */}
        </div>

        {/* Add to Cart Button */}
      </div>
    </div>
  );
};
export default Courses;
