import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import { Link, useNavigate } from "react-router-dom";

function PartnerLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("Partner Portal");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showResetPassword, setshowResetPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resend, setResend] = useState(false);
  const [otpExpiry, setOtpExpiry] = useState(null);
  const { login } = useAuth();
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const formatExpiryDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };
  const handlePasswordLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let endpoint = "";
      let dashboardPath = "";

      endpoint = "/v1/auth/contrib_login";
      dashboardPath = "/contrib_dashboard";

      const response = await axiosInstance.post(endpoint, {
        email,
        password,
      });

      const { user } = response.data;
      login(user);
      navigate(dashboardPath);
    } catch (error) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };
  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email.");
      return;
    }
    setLoading(true);
    setError("");
    // setShowBack(true);
    setMessage("");
    // let user_role = "";
    // try {
    //   const response = await axiosInstance.post("/v1/auth/get_user_role", {
    //     email,
    //   });
    //   const data = response.data;
    //   const { role, signin_type } = data.user;

    //   if (signin_type !== "pwd") {
    //     setError("You do not have password account.");
    //     setLoading(false);
    //     return;
    //   }
    //   setUserRole(role);
    //   user_role = role;
    //   setResetPassword(true);

    //   if (!data.success && data.message === "User not found") {
    //     setError("No account found with this email");
    //     setLoading(false);
    //     return;
    //   }
    // } catch (error) {
    //   setError("Something went wrong. Please try again.");
    //   setLoading(false);
    //   return;
    // }
    setResetPassword(true);
    try {
      const response = await axiosInstance.post("/v1/auth/send_reset_otp", {
        email,
        userType: "contributor",
        resend,
      });

      if (response.data.success) {
        setMessage("Reset OTP sent successfully!");
        setIsOtpSent(true);
      } else if (!response.data.success && response.data.otpData) {
        // OTP already exists case
        const expiryDate = new Date(response.data.otpData.date);
        expiryDate.setDate(expiryDate.getDate() + 2);
        setOtpExpiry(expiryDate);
        setResend(true);
        setIsOtpSent(true);
        setMessage(
          `An OTP has already been sent. Valid until ${formatExpiryDate(
            expiryDate
          )}`
        );
      }
    } catch (error) {
      setIsOtpSent(true);
      setError("Error sending reset OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetVerifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/otp/verify-otp", {
        email,
        otpCode: otp,
      });

      if (response.status === 200 && response.data.success) {
        setResetPassword(false);
        setshowResetPassword(true);
        setResend(false);
        setLoading(false);
        setMessage("");
      } else {
        setMessage(response.data.message || "Invalid OTP or OTP expired.");
        setLoading(false);
      }
    } catch (error) {
      setMessage("Error verifying OTP. Please try again.");
      setLoading(false);
    }
  };
  const handleConfrimPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/v1/auth/reset_password", {
        email,
        newPassword,
        user_role: "contributor",
      });

      if (response.data.success) {
        setMessage(
          "Password reset successfully! You can now sign in with your new password."
        );
        setNewPassword("");
        setshowResetPassword(false);

        setConfirmPassword("");
        // setTimeout(() => {
        //   setUserRole(null);
        // }, 3000);
      } else {
        setError(response.data.message || "Failed to reset password");
      }
    } catch (error) {
      setError("Error resetting password. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const renderLoginForm = () => {
    if (showResetPassword) {
      return (
        <Box
          component="form"
          onSubmit={handleConfrimPassword}
          sx={{ width: "100%", gap: 2 }}
        >
          <TextField
            required
            fullWidth
            id="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!error}
            margin="normal"
          />
          <TextField
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!error}
            helperText={error}
            margin="normal"
          />
          <button
            disabled={loading}
            type="submit"
            className="bg-[#299aa1] px-6 text-md text-white my-4 py-3 rounded-full flex items-start"
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Reset Password"
            )}
          </button>
          {message && (
            <Typography color="success" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </Box>
      );
    }
    if (resetPassword) {
      return (
        <Box sx={{ width: "100%", gap: 2 }}>
          {!isOtpSent ? (
            <Typography>Sending OTP to {email}...</Typography>
          ) : (
            <>
              <TextField
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
              />
              <Button
                fullWidth
                variant="contained"
                onClick={handleResetVerifyOtp}
                disabled={loading}
                sx={{
                  mt: 2,
                  backgroundColor: "#23436d",
                  "&:hover": { backgroundColor: "#00314D" },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </>
          )}
          {message && (
            <Typography
              color={message.includes("successfully") ? "success" : "error"}
              sx={{ mt: 2 }}
            >
              {message}
            </Typography>
          )}
          {resend && (
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleResetPassword}
              sx={{
                mt: 2,
                backgroundColor: "#23436d",
                "&:hover": { backgroundColor: "#00314D" },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Send otp again"
              )}
            </Button>
          )}
        </Box>
      );
    }
    return (
      <Box
        component="form"
        onSubmit={handlePasswordLogin}
        sx={{ width: "100%", gap: 2 }}
      >
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          margin="normal"
        />
        <TextField
          required
          fullWidth
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error}
          helperText={error}
          margin="normal"
        />
        <button
          disabled={loading}
          type="submit"
          className="bg-[#299aa1] px-6 text-lg hover:bg-[#23436d] transition-colors duration-200 ease-in text-white w-full my-4 py-3 rounded-full flex justify-center items-center"
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Sign In"
          )}
        </button>
        {message && (
          <Typography color="success" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
        <div className="flex justify-end">
          <Typography
            variant="body2"
            onClick={handleResetPassword}
            sx={{ cursor: "pointer", color: "#23436d", mt: 1 }}
          >
            Reset Your Password
          </Typography>
        </div>
        {/* <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            alignContent: "center",
            mt: 2,
            backgroundColor: "#299aa1",
            "&:hover": { backgroundColor: "#248c94" }, // Hover color closer to #299aa1
            height: 48,
            borderRadius: "24px", // Fully rounded
            alignItems: "flex-start", // Align items to start
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Sign In"
          )}
        </Button> */}
      </Box>
    );
  };
  return (
    <div className="bg-[#23436d] flex items-center justify-center min-h-screen no">
      {/* {showBack && (
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <Fab
          aria-label="go back"
          style={{
            color: "white",
            backgroundColor: "#23436d",
            position: "fixed",
            top: "2rem",
            left: "2rem",
            zIndex: 1000,
          }}
          onClick={handleGoBack}
        >
          <ArrowBack />
        </Fab>
      </div>
    )} */}
      <Container component="main" maxWidth="sm">
        {/* <div className="mb-6 text-center">
        <h2 className="font-bold text-4xl">
          LMS{" "}
          <span className="bg-[#23436d] text-white px-2 rounded-md">
            Login
          </span>
        </h2>
      </div> */}
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="w-full mb-8">
            <div className="flex w-full  justify-between mb-8">
              <h2 className="text-2xl text-[#23436d]">Welcome Back!</h2>

              <h2 className="text-xl items-end text-[#299aa1]">
                {userRole.charAt(0).toUpperCase() + userRole.slice(1)}
              </h2>
            </div>
          </div>
          {renderLoginForm()}
          <h2 className="text-lg text-black my-4 ">
            <a
              href="/terms-of-services"
              className="underline text-[#23436d]"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Services
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="text-[#23436d] underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </h2>
        </Paper>
      </Container>
    </div>
  );
}

export default PartnerLogin;
