import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contributor from "./sections/Contributor";
import GroupLeader from "./sections/GroupLeader";
import Subscriber from "./sections/Subscriber";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Add as AddIcon, People as PeopleIcon } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Book as BookIcon,
  Collections as CollectionsIcon,
} from "@mui/icons-material";
import {
  FormLabel,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Modal,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  styled,
  Chip,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";
import axios from "axios";
import { Loader } from "../../utils/Loader";
import { Link } from "react-router-dom";
import Heading from "../../components/ui";
const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "24px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  backgroundColor: selected ? "#e3f2fd" : "#ffffff",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
}));
const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  padding: "24px",
});
const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

// A11y props for accessibility
const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const GroupManagmentPage = () => {
  const { currentUser } = useAuth();

  const [coursesData, setCoursesData] = useState([]);
  const [bundlesData, setBundlesData] = useState([]);
  const getAllCourses = async () => {
    try {
      const result = await axiosInstance.get(
        `/purchased_courses/all/${currentUser.email}`
      );
      const courses = result.data.data;
      setCoursesData(courses);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllBundles = async () => {
    try {
      const result = await axiosInstance.get(
        `/purchased_bundles/all/${currentUser.email}`
      );
      const bundles = result.data.data;
      setBundlesData(bundles);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllBundles();
  }, []);
  useEffect(() => {
    getAllCourses();
  }, []);

  const [AllGroups, setAllGroups] = useState([]);
  const [SelectedGroupName, setSelectedGroupName] = useState("");
  const [SelectedGroupId, setSelectedGroupId] = useState("");
  const [addgroupmodal, setaddgroupmodal] = useState(false);
  const [SelectedCourseTitle, setSelectedCourseTitle] = useState("");
  const [SelectedCourseId, setSelectedCourseId] = useState("");
  const [SelectedBundleTitle, setSelectedBundleTitle] = useState("");
  const [SelectedBundleId, setSelectedBundleId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({
    groupname: "",
    signinType: "with_pwd",
    gl_firstname: "",
    gl_lastname: "",
    gl_email: "",
  });

  const handleAttachCoursetoGroup = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/attach/course_to_group", {
        group_id: SelectedGroupId,
        course_id: SelectedCourseId,
        email: currentUser.email,
      });
      if (response.data.success) {
        toast.success("Course attached to group successfully!");
        setLoading(false);
        getAllCourses();
        getAttachedCoursesOfGroup(SelectedGroupId);
      } else if (!response.data.success) {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };
  const handleAttachBundletoGroup = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/attach/bundles_to_group", {
        group_id: SelectedGroupId,
        bundle_id: SelectedBundleId,
        email: currentUser.email,
      });

      if (response.data.success) {
        toast.success("Bundle attached to group successfully!");
        setLoading(false);
        getAllBundles();
        getAttachedCoursesOfGroup(SelectedGroupId);
      } else if (!response.data.success) {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleAttach = () => {
    if (selectedOption === "course") {
      handleAttachCoursetoGroup();
    } else if (selectedOption === "bundle") {
      handleAttachBundletoGroup();
    }

    if (!loading) {
      handleCloseModal();
    }
  };

  const fetchAllGroups = async () => {
    try {
      const resp = await axiosInstance.get(`/groups/all/${currentUser.email}`);
      setAllGroups(resp.data.groups);
    } catch (error) {
      console.log("Error fetching all groups!");
    }
  };

  useEffect(() => {
    fetchAllGroups();
  }, [currentUser]);

  const handleAddGroup = () => {
    setaddgroupmodal(true);
  };

  const handleCloseGroupModal = () => {
    setaddgroupmodal(false);
  };

  // const handleGroupSubmit = async () => {
  //   try {
  //     const resp = await axiosInstance.post("/groups/add", {
  //       name: formData.groupname,
  //       signin_type: formData.signinType,
  //       creator_role: currentUser.role,
  //       creator_email: currentUser.email
  //     })
  //     await axios.post(
  //       `${GoPhishPublicURL}/api/groups/?api_key=${GoPhishAccountAPIKey}`,
  //       {
  //         "name": formData.groupname,
  //         "targets": [
  //           {
  //             "email": currentUser.email,
  //             "first_name": currentUser.firstname,
  //             "last_name": currentUser.lastname,
  //             "position": ""
  //           }
  //         ]
  //       }
  //     );
  //     if (resp.status === 201) {
  //       toast.success('Group created successfully');
  //       handleCloseGroupModal();
  //       fetchAllGroups();
  //     }
  //   } catch (error) {
  //     console.error('Failed to create group:', error);
  //     toast.error('Failed to create group');
  //   }
  // }

  const handleGroupSubmit = async () => {
    try {
      const group_data = await axios.post(
        `${GoPhishPublicURL}/api/groups/?api_key=${GoPhishAccountAPIKey}`,
        {
          name: formData.groupname,
          targets: [
            {
              email: formData.gl_email,
              first_name: formData.gl_firstname,
              last_name: formData.gl_lastname,
              position: "Group Leader",
            },
          ],
        }
      );

      const resp = await axiosInstance.post("/groups/add", {
        name: formData.groupname,
        signin_type: formData.signinType,
        creator_role: currentUser.role,
        creator_email: currentUser.email,
        gophish_groupid: group_data.data.id,
      });

      const leaderResp = await axiosInstance.post(`/groupleaders/add`, {
        firstname: formData.gl_firstname,
        lastname: formData.gl_lastname,
        email: formData.gl_email,
        group_id: resp.data.ids.lms_groupid,
        currentUser: currentUser,
      });

      if (resp.status === 201 && resp.data.message) {
        toast.success(resp.data.message);
      } else if (leaderResp.status === 200 && leaderResp.data.message) {
        toast.success(leaderResp.data.message);
      }

      handleCloseGroupModal();
      handleCloseAddGLModal();
      fetchAllGroups();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to create group");
      }
      console.error("Failed to create group:", error);
    }
  };

  const [openAddGLModal, setopenAddGLModal] = useState(false);

  const handleOpenAddGLModal = () => {
    setopenAddGLModal(true);
  };
  const handleCloseAddGLModal = () => {
    setopenAddGLModal(false);
  };

  const handleGroupChange = (event, child) => {
    setSelectedGroupId(child.props.id);
    setSelectedGroupName(event.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedOption(selectedValue);

    if (selectedValue === "course") {
      setModalData(coursesData);
    } else if (selectedValue === "bundle") {
      setModalData(bundlesData);
    }

    setModalOpen(true);
  };
  const handleItemSelect = (id) => {
    if (selectedOption === "course") {
      setSelectedCourseId(id);
    } else {
      setSelectedBundleId(id);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [ListOfAttachedCourses, setListOfAttachedCourses] = useState([]);
  const getAttachedCoursesOfGroup = async (id) => {
    try {
      setLoading(true);
      const data = await axiosInstance.get(`/get_attached_courses/${id}`);
      setListOfAttachedCourses(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(
        "error getting attached courses to this selected group: ",
        error
      );
    }
  };
  useEffect(() => {
    if (SelectedGroupId) {
      getAttachedCoursesOfGroup(SelectedGroupId);
    }
  }, [SelectedGroupId]);
  const filteredData = modalData.filter((item) => {
    const title =
      selectedOption === "course"
        ? item.courseDetails.title
        : item.bundleDetails.name;
    return title.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const isAdmin = true;
  return (
    <>
      <div className="w-full  flex flex-col  justify-start items-center">
        <div className="w-[100%] flex flex-row gap-5 justify-between items-center mb-4">
          {/* <h1 className="text-[#23436d] font-bold text-[40px] mt-5 mb-5">
            Groups Management
          </h1> */}
          <Heading text="Groups Management" />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddGroup}
            sx={{
              fontWeight: "bold",
              textTransform: "none",
              px: 3,
              py: 1,
              borderRadius: 2,
              bgcolor: "#299aa1",
              boxShadow: 2,
              "&:hover": {
                bgcolor: "#00A3AE",
                boxShadow: 4,
              },
            }}
          >
            Create Group
          </Button>
        </div>
        {!SelectedGroupId && (
          <div class="space-y-2 w-full pb-4">
            <div
              role="alert"
              className="bg-blue-100 dark:bg-blue-900 border-l-4 border-[#23436d] dark:border-blue-700 text-[#23436d] dark:text-blue-100 p-2 !rounded-lg flex items-center transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-800 transform hover:scale-105 animate-bounce "
            >
              {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
              <svg
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                className="h-5 w-5 flex-shrink-0 mr-2 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                />
              </svg>
              <p class="text-lg font-semibold">
                Please select a group to continue.
              </p>
            </div>
          </div>
        )}
        <div className="w-[100%]  flex flex-row justify-start gap-5 items-center">
          <FormControl
            sx={{
              width: "20%",
              backgroundColor: "#f9f9f9",
              // borderRadius: "8px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ccc",
                },
                "&:hover fieldset": {
                  borderColor: "#888",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#23436d",
                  borderWidth: "2px",
                },
              },
            }}
          >
            <InputLabel id="groups">Select Group:</InputLabel>
            <Select
              labelId="groups"
              id="groups"
              value={SelectedGroupName}
              label="Select Group"
              onChange={handleGroupChange}
              sx={{
                "& .MuiSelect-select": {
                  padding: "14px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#888",
                },
              }}
            >
              {AllGroups?.map((group, index) => (
                <MenuItem
                  id={group.id}
                  key={index}
                  value={group.name}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#23436d",
                      color: "#fff",
                    },
                  }}
                >
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* {SelectedGroupId && (
						<FormControl
							sx={{
								width: "30%",
								backgroundColor: "#f9f9f9",
								borderRadius: "8px",
								boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
								"&:hover": {
									backgroundColor: "#f0f0f0",
								},
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "#ccc",
									},
									"&:hover fieldset": {
										borderColor: "#888",
									},
									"&.Mui-focused fieldset": {
										borderColor: "#23436d",
										borderWidth: "2px",
									},
								},
							}}
						>
							<InputLabel id="options">Select Option:</InputLabel>
							<Select
								labelId="options"
								id="options"
								value={selectedOption}
								label="Select Option"
								onChange={handleOptionChange}
								sx={{
									"& .MuiSelect-select": {
										padding: "14px",
									},
									"& .MuiSvgIcon-root": {
										color: "#888",
									},
								}}
							>
								<MenuItem
									sx={{
										"&:hover": {
											backgroundColor: "#23436d",
											color: "#fff",
										},
									}}
									value="course"
									onClick={() =>
										handleOptionChange({ target: { value: "course" } })
									}
								>
									Select Course
								</MenuItem>
								<MenuItem
									sx={{
										"&:hover": {
											backgroundColor: "#23436d",
											color: "#fff",
										},
									}}
									value="bundle"
									onClick={() =>
										handleOptionChange({ target: { value: "bundle" } })
									}
								>
									Select Bundle
								</MenuItem>
							</Select>
						</FormControl>
					)} */}
          {SelectedGroupId && (
            <Box>
              <Button
                variant="contained"
                onClick={handleClick} // Opens the dropdown menu
                endIcon={<ExpandMoreIcon />}
                sx={{
                  // bgcolor: "#02496F",
                  fontWeight: "bold",
                  textTransform: "none",
                  marginLeft: 4,
                  px: 3,
                  py: 1,
                  borderRadius: 2,
                  bgcolor: "#299aa1",
                  boxShadow: 2,
                  "&:hover": {
                    bgcolor: "#00A3AE",
                    boxShadow: 4,
                  },
                }}
              >
                Select Option
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() =>
                    handleOptionChange({ target: { value: "course" } })
                  }
                >
                  <ListItemIcon>
                    <BookIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Add Course</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleOptionChange({ target: { value: "bundle" } })
                  }
                >
                  <ListItemIcon>
                    <CollectionsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Add Bundle</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          )}

          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,

                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                borderRadius: "12px",
              }}
            >
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2, textAlign: "center", fontWeight: "bold" }}
              >
                {selectedOption === "course"
                  ? "Available Courses"
                  : "Available Bundles"}
              </Typography>

              <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  mb: 2,
                  width: "100%",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 16px",
                  borderBottom: "2px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Name
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  Available Seats
                </Typography>
              </Box>

              <Paper
                sx={{
                  flex: 1,
                  maxHeight: "calc(80vh - 160px)",
                  overflowY: "auto",
                  p: 2,
                  mb: 2,
                  borderRadius: "8px",
                }}
              >
                <Grid container spacing={2}>
                  {filteredData.map((item) => {
                    const itemId =
                      selectedOption === "course"
                        ? item.courseDetails.id
                        : item.bundle_id;
                    const isSelected =
                      selectedOption === "course"
                        ? SelectedCourseId === itemId
                        : SelectedBundleId === itemId;

                    return (
                      <Grid item xs={12} key={itemId}>
                        <Card
                          sx={{
                            boxShadow: isSelected
                              ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                              : "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            border: isSelected
                              ? "2px solid #02496F"
                              : "1px solid #ddd",
                            backgroundColor: "white",

                            fontSize: { xs: "10px", sm: "12px" }, // Control text size for responsiveness
                          }}
                        >
                          <CardActionArea
                            onClick={() => handleItemSelect(itemId)}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "10px", // Reducing padding for a more compact look
                                height: { xs: "48px", sm: "56px" }, // Matching height from Uiverse.io example
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Radio
                                  checked={isSelected}
                                  onChange={() => handleItemSelect(itemId)}
                                  sx={{ p: 0, mr: 1 }} // Adjusting margin for a tighter fit
                                />
                                <Typography
                                  variant="subtitle2" // Smaller font variant for compactness
                                  sx={{
                                    color: "black",
                                    fontSize: { xs: "10px", sm: "12px" },
                                  }} // Text color and size
                                >
                                  {selectedOption === "course"
                                    ? item.courseDetails.title
                                    : item.bundleDetails.name}
                                </Typography>
                              </Box>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "text.secondary",
                                  fontSize: { xs: "10px", sm: "12px" },
                                }}
                              >
                                {item.seats}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  position: "sticky",
                  bottom: 0,
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                  padding: "8px 0",
                  boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",
                  zIndex: 2,
                }}
              >
                <Button onClick={handleCloseModal} sx={{ mr: 2 }}>
                  Cancel
                </Button>
                <Button
                  onClick={handleAttach}
                  variant="contained"
                  disabled={loading || !(SelectedCourseId || SelectedBundleId)}
                  sx={{
                    bgcolor: "#02496F",
                    "&:hover": {
                      bgcolor: "#1b355d",
                    },
                  }}
                >
                  {loading
                    ? "Loading..."
                    : `Attach ${
                        selectedOption === "course" ? "Course" : "Bundle"
                      }`}
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
        {loading && <Loader />}
        {!loading && SelectedGroupId && (
          <Box sx={{ width: "100%", mt: 1, mb: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#02496F",
                  },
                }}
              >
                {["Group Leader", "Subscriber", "Attached Courses"].map(
                  (label, index) => (
                    <Tab
                      key={index}
                      label={label}
                      {...a11yProps(index)}
                      sx={{
                        "&.Mui-selected": {
                          color: "#02496F",
                          fontWeight: "bold",
                        },
                        "&:hover": {
                          color: "#23436d",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )
                )}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <GroupLeader g_id={SelectedGroupId} />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Subscriber g_id={SelectedGroupId} />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Box sx={{ width: "100%", my: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="h4"
                      component="h1"
                      sx={{
                        fontWeight: "bold",
                        // color: theme.palette.primary.main,
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                      }}
                    >
                      Attached Courses
                    </Typography>
                    <Chip
                      icon={<PeopleIcon />}
                      label={`${ListOfAttachedCourses?.length} ${
                        ListOfAttachedCourses?.length === 1
                          ? "Courses"
                          : "Courses"
                      }`}
                      sx={{
                        bgcolor: "#02496F",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        // height: "auto",
                        p: "2px",
                        "& .MuiChip-icon": {
                          color: "white",
                          fontSize: "1.2rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ width: "100%", mt: 2, mb: 2 }}>
                {ListOfAttachedCourses.length > 0 ? (
                  <Grid container spacing={2}>
                    {ListOfAttachedCourses.map((course, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2.4}
                        key={index}
                      >
                        <Link
                          to={`/dashboard/course_details?id=${course.id}${
                            isAdmin ? "&admin=true" : ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledCard>
                            <StyledCardContent>
                              <Typography
                                variant="h6"
                                component="h2"
                                gutterBottom
                              >
                                {course.title}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Course ID: {course.id}
                              </Typography>
                            </StyledCardContent>
                          </StyledCard>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Paper
                    elevation={1}
                    sx={{
                      p: 2,
                      bgcolor: "background.default",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      No Attached Courses.
                    </Typography>
                  </Paper>
                )}
              </Box>
            </TabPanel>
          </Box>
        )}

        <Dialog open={addgroupmodal} onClose={handleCloseGroupModal} fullWidth>
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Group
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="groupname"
              name="groupname"
              label="Group Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.groupname}
              onChange={(e) =>
                setFormData({ ...formData, groupname: e.target.value })
              }
            />
          </DialogContent>
          <DialogContent>
            <FormLabel id="signinType">Select Signin Type:</FormLabel>
            <RadioGroup
              aria-labelledby="signinType"
              defaultValue="with_pwd"
              name="signinType"
              value={formData.signinType}
              onChange={(e) =>
                setFormData({ ...formData, signinType: e.target.value })
              }
            >
              <FormControlLabel
                value="pwd_less"
                control={<Radio />}
                label="Password Less"
              />
              <FormControlLabel
                value="with_pwd"
                control={<Radio />}
                label="With Password"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions
            sx={{
              padding: "16px", // Add padding to enhance spacing in DialogActions
              display: "flex",

              // justifyContent: "space-between", // Space the buttons apart for a better UI
            }}
          >
            {/* Cancel button with red color */}
            <Button
              onClick={handleCloseGroupModal}
              sx={{
                width: "84px",
                color: "white", // White text for better readability
                backgroundColor: "#d32f2f", // Red background
                "&:hover": {
                  backgroundColor: "#b71c1c", // Darker red on hover
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>

            {/* Add button with custom color */}
            <Button
              onClick={handleOpenAddGLModal}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#02496F", // Custom blue color for Add
                "&:hover": {
                  backgroundColor: "#023A5A", // Slightly darker shade on hover
                },
              }}
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddGLModal} onClose={handleCloseAddGLModal} fullWidth>
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Group Leader
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_firstname"
              name="gl_firstname"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_firstname}
              onChange={(e) =>
                setFormData({ ...formData, gl_firstname: e.target.value })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_lastname"
              name="gl_lastname"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.gl_lastname}
              onChange={(e) =>
                setFormData({ ...formData, gl_lastname: e.target.value })
              }
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="gl_email"
              name="gl_email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={formData.gl_email}
              onChange={(e) =>
                setFormData({ ...formData, gl_email: e.target.value })
              }
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseAddGLModal}>Cancel</Button>
            <Button onClick={handleGroupSubmit}>Add</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default GroupManagmentPage;
