import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
  InputLabel,
  Box,
  Grid,
  Card,
  CardMedia,
  IconButton,
  CardContent,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { h5pVideosData } from "./data/h5pVideosData";
import Spinner from "../../shared/components/Spinner";
import EditableCourseDetails from "../components/EditableCourseDetails";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../components/ui";
// import Heading from "./ui";

const CourseDetails = () => {
  const [loading_course, setloading_course] = useState(true);
  const [editImage, setEditImage] = useState(false);
  const [admin, setAdmin] = useState("");
  const [courseId, setCourseId] = useState("");
  const [courseDetails, setCourseDetails] = useState({
    id: "",
    title: "",
    description: "",
    priceperseat: "",
    featuredImage: null,
    lessons: [],
  });

  const [newLesson, setNewLesson] = useState({
    title: "",
    description: "",
    lesson_video_path: "",
    lesson_video_name: "",
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const nav = useNavigate();
  const getCourseDetails = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/courses/all_course_details/${id}`
      );
      const data = response.data.data;
      setCourseDetails({
        id: data.id,
        title: data.title,
        featuredImage: data.featuredImage,
        description: data.description,
        priceperseat: data.priceperseat,
        lessons: data.lessons
          ? data.lessons.map((lesson) => ({
              ...lesson,
              lesson_video_path: lesson.materials
                ? lesson.materials[0].doc_link
                : "",
              lesson_video_name: lesson.materials
                ? lesson.materials[0].doc_name
                : "",
              isEditing: false,
            }))
          : [],
      });
      setloading_course(false);
    } catch (error) {
      console.log("Error getting course details:", error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("id");
    setCourseId(id);
    const admin = searchParams.get("admin");
    setAdmin(admin);

    if (id) {
      getCourseDetails(id);
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleLessonChange = (e, index, videoName) => {
    const { name, value } = e.target;
    const updatedLessons = [...courseDetails.lessons];
    if (name === "lesson_video_path") {
      updatedLessons[index] = {
        ...updatedLessons[index],
        [name]: value,
        lesson_video_name: videoName || updatedLessons[index].lesson_video_name,
      };
    } else {
      updatedLessons[index] = { ...updatedLessons[index], [name]: value };
    }
    setCourseDetails({ ...courseDetails, lessons: updatedLessons });
  };

  const handleNewLessonChange = (e) => {
    const { name, value } = e.target;
    setNewLesson({ ...newLesson, [name]: value });
  };

  const addLesson = async () => {
    if (newLesson.title && newLesson.description) {
      const formData = new FormData();
      formData.append("title", newLesson.title);
      formData.append("description", newLesson.description);
      formData.append("lesson_video_path", newLesson.lesson_video_path);
      formData.append("lesson_video_name", newLesson.lesson_video_name);
      try {
        const response = await axiosInstance.post(
          `/courses/add_lesson/${courseDetails.id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success) {
          toast.success("Lesson Added!");
          getCourseDetails(courseDetails.id);
          setNewLesson({
            title: "",
            description: "",
            lesson_video_path: "",
            lesson_video_name: "",
          });
          setIsDialogOpen(false);
        }
      } catch (error) {
        console.log("Error adding lesson:", error);
      }
    } else {
      alert("Title and description are required.");
    }
  };

  const updateLesson = async (index) => {
    const lessonToUpdate = courseDetails.lessons[index];
    try {
      const response = await axiosInstance.put(
        `/courses/update_lesson/${courseDetails.id}/${lessonToUpdate.id}`,
        {
          title: lessonToUpdate.title,
          description: lessonToUpdate.description,
          lesson_video_path: lessonToUpdate.lesson_video_path,
          lesson_video_name: lessonToUpdate.lesson_video_name,
        }
      );
      if (response.data.success) {
        const updatedLessons = [...courseDetails.lessons];
        updatedLessons[index] = { ...updatedLessons[index], isEditing: false };
        setCourseDetails({ ...courseDetails, lessons: updatedLessons });
        toast.success("Lesson Updated!");
      }
    } catch (error) {
      console.log("Error updating lesson:", error);
    }
  };

  const deleteLesson = async (index) => {
    const lessonToDelete = courseDetails.lessons[index];
    try {
      const response = await axiosInstance.delete(
        `/courses/delete_lesson/${courseDetails.id}/${lessonToDelete.id}`
      );
      if (response.data.success) {
        const updatedLessons = [...courseDetails.lessons];
        updatedLessons.splice(index, 1);
        setCourseDetails({ ...courseDetails, lessons: updatedLessons });
        toast.success("Lesson Deleted!");
      }
    } catch (error) {
      console.log("Error deleting lesson:", error);
    }
  };

  const handleImageSubmit = async (e) => {
    const formData = new FormData();
    if (newImage) {
      formData.append("featuredImage", newImage);
    }
    try {
      const response = await axiosInstance.put(
        `/courses/set_image/${courseDetails.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        toast.success("Featured Image Updated!");
        getCourseDetails(courseDetails.id);
        setNewImage(null);
        setImagePreview(null);
        setEditImage(false);
      }
    } catch (error) {
      console.log("Error updating featured image:", error);
    }
  };
  const handleSave = async (updatedFields) => {
    try {
      await axiosInstance.put(
        `/courses/update/${courseDetails.id}`,
        updatedFields
      );
      setCourseDetails((prev) => ({ ...prev, ...updatedFields }));
      toast.success("Course details updated successfully!"); // Success toast
    } catch (error) {
      console.log("Error updating course details:", error);
      toast.error("Error updating course details!"); // Error toast
    }
  };

  const toggleEditing = (index) => {
    const updatedLessons = [...courseDetails.lessons];
    updatedLessons[index].isEditing = !updatedLessons[index].isEditing;
    setCourseDetails({ ...courseDetails, lessons: updatedLessons });
  };

  return (
    <div className="w-full flex flex-col  justify-start items-center">
      <div className="w-full flex flex-col justify-center items-start">
        <Heading text={"Course Details"} />
      </div>
      {loading_course === false ? (
        <div className="w-full">
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <EditableCourseDetails
                courseDetails={courseDetails}
                onSave={handleSave}
                admin={admin}
              />

              <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
                Lessons
              </Typography>
              {courseDetails.lessons.map((lesson, index) => (
                <Accordion key={index} sx={{ mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      bgcolor: "#FFFFF",
                      color: "#02496f",
                      // borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <Typography>{lesson.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {admin && (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={lesson.isEditing}
                            onChange={() => toggleEditing(index)}
                            color="primary"
                          />
                        }
                        label="Enable Editing"
                      />
                    )}
                    <TextField
                      label="Title"
                      name="title"
                      value={lesson.title}
                      onChange={(e) => handleLessonChange(e, index)}
                      fullWidth
                      disabled={!lesson.isEditing}
                      margin="normal"
                    />
                    <TextField
                      label="Description"
                      name="description"
                      value={lesson.description}
                      onChange={(e) => handleLessonChange(e, index)}
                      fullWidth
                      multiline
                      disabled={!lesson.isEditing}
                      margin="normal"
                    />
                    {lesson.materials[0] !== "" && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel id={`lesson-video-${index}`}>
                          Update video for lesson
                        </InputLabel>
                        <Select
                          labelId={`lesson-video-${index}`}
                          name="lesson_video_path"
                          value={lesson.lesson_video_path}
                          onChange={(e) =>
                            handleLessonChange(e, index, e.target.value)
                          }
                          disabled={!lesson.isEditing}
                        >
                          {h5pVideosData &&
                            h5pVideosData.map((video) => (
                              <MenuItem key={video.path} value={video.path}>
                                {video.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                    {admin && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => updateLesson(index)}
                          disabled={!lesson.isEditing}
                          sx={{
                            bgcolor: "#299aa1",
                            "&:hover": {
                              bgcolor: "#00A3AE",
                            },
                            mr: 1,
                          }}
                        >
                          Update Lesson
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => deleteLesson(index)}
                        >
                          Delete Lesson
                        </Button>
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="flex gap-4">
                {admin && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsDialogOpen(true)}
                    sx={{
                      bgcolor: "#299aa1",

                      "&:hover": {
                        bgcolor: "#00A3AE",
                      },
                      mt: 1,
                    }}
                  >
                    Add Lesson
                  </Button>
                )}

                {admin && (
                  <Link to={`/dashboard/view_course?course_id=${courseId}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        bgcolor: "#299aa1",

                        "&:hover": {
                          bgcolor: "#00A3AE",
                        },
                        mt: 1,
                      }}
                    >
                      Review Course
                    </Button>
                  </Link>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={5}>
              <Card sx={{ position: "relative" }}>
                {courseDetails.featuredImage && !editImage ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${courseDetails.featuredImage}`}
                    className={`w-full ${
                      admin ? "h-[250px]" : "h-[218px]"
                    } object-cover `} // Set height to 250px
                    alt={courseDetails.title}
                  />
                ) : imagePreview ? (
                  <img
                    src={imagePreview}
                    className={`w-full  ${
                      admin ? "h-[250px]" : "h-[218px]"
                    } object-cover`} // Set height to 250px
                    alt={courseDetails.title}
                  />
                ) : (
                  <div
                    className={`w-full ${
                      admin ? "h-[250px]" : "h-[218px]"
                    } flex items-center justify-center bg-gray-300`}
                  >
                    <Typography
                      sx={{ color: "#7e7e7e", fontSize: "18px" }}
                      align="center"
                    >
                      No Image Uploaded
                    </Typography>
                  </div>
                )}

                {admin && (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 8,
                      bgcolor: "#23436d",
                      right: 8,
                    }}
                    onClick={() => setEditImage(!editImage)}
                  >
                    <EditIcon className="text-white" />
                  </IconButton>
                )}
                {editImage && (
                  <CardContent>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      id="featured-image-input"
                    />
                    <label htmlFor="featured-image-input">
                      <Button
                        variant="contained"
                        component="span"
                        fullWidth
                        sx={{
                          mb: 2,
                          bgcolor: "#00A3AE",
                          "&:hover": {
                            bgcolor: "#00A3AE",
                            boxShadow: 4,
                          },
                        }}
                      >
                        Choose New Image
                      </Button>
                    </label>
                    <Button
                      onClick={handleImageSubmit}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        mb: 2,
                        bgcolor: "#00A3AE",
                        "&:hover": {
                          bgcolor: "#00A3AE",
                          boxShadow: 4,
                        },
                      }}
                    >
                      Update Featured Image
                    </Button>
                  </CardContent>
                )}
              </Card>
            </Grid>
          </Grid>

          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
            <DialogTitle>Add New Lesson</DialogTitle>
            <DialogContent>
              <TextField
                label="Title"
                name="title"
                value={newLesson.title}
                onChange={handleNewLessonChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                name="description"
                value={newLesson.description}
                onChange={handleNewLessonChange}
                fullWidth
                multiline
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="new-lesson-video">
                  Select a video for lesson
                </InputLabel>
                <Select
                  labelId="new-lesson-video"
                  name="lesson_video_path"
                  value={newLesson.lesson_video_path}
                  onChange={(e) =>
                    handleNewLessonChange({
                      target: {
                        name: "lesson_video_path",
                        value: e.target.value,
                      },
                    })
                  }
                >
                  {h5pVideosData &&
                    h5pVideosData.map((video) => (
                      <MenuItem key={video.path} value={video.path}>
                        {video.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={addLesson} color="primary">
                Add Lesson
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <>
          <div className="w-[95%] bg-white flex flex-col justify-center items-center p-6 rounded-lg shadow-md">
            <Spinner />
          </div>
        </>
      )}
    </div>
  );
};

export default CourseDetails;
