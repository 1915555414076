import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardContent,
  Box,
  Tab,
  Tabs,
  Fab,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import SeatDiscountConfig from "../components/SeatDiscountConfig";
import Heading from "../../components/ui";

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  backgroundColor: "#23436d",
  color: "white",
  "&:hover": {
    backgroundColor: "#003756",
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  borderColor: "#23436d",
  paddingInline: "16px",
  color: "#23436d",
  "&:hover": {
    borderColor: "#23436d",
    backgroundColor: "rgba(0, 72, 111, 0.04)",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  backgroundColor: "red",
  color: "white",
  "&:hover": {
    backgroundColor: "#003756",
  },
}));
const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "24px",
  cursor: "pointer",
  transition: "all 0.3s ease",
  backgroundColor: selected ? "#e3f2fd" : "#ffffff",
  "&:hover": {
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    transform: "translateY(-5px)",
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  padding: "24px",
});

const PriceTypography = styled(Typography)({
  marginTop: "16px",
  "& .price": {
    fontSize: "2.5rem",
    fontWeight: 300,
    color: "rgba(0, 0, 0, 0.87)",
  },
  "& .period": {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
});

const ItemCard = ({
  item,
  isSelected,
  onSelect,
  discount,
  onUpdateDiscount,
}) => {
  const isCourse = !item.bundleId;
  const itemId = isCourse ? item.id : item.bundleId;
  const details = isCourse ? item : item;

  const getTitle = () => (isCourse ? details?.title : details?.name);
  const getPrice = () => {
    const price = isCourse ? details?.priceperseat : details?.seatprice;
    return typeof price === "number" ? price : parseFloat(price) || 0;
  };

  const handleUpdateClick = (e) => {
    e.stopPropagation();
    onSelect(itemId);
    onUpdateDiscount(itemId, discount);
  };

  const originalPrice = getPrice();
  const discountedPrice =
    discount && discount.enable === "true"
      ? originalPrice * (1 - (parseFloat(discount.percentage) || 0) / 100)
      : originalPrice;

  const formatPrice = (price) => {
    return typeof price === "number" ? price.toFixed(2) : "0.00";
  };

  return (
    <StyledCard selected={isSelected} onClick={() => onSelect(itemId)}>
      <StyledCardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {getTitle()}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {isCourse ? "Course" : "Bundle"}
        </Typography>
        <PriceTypography>
          {discount && discount.enable === "true" ? (
            <>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "gray",
                  marginRight: "10px",
                }}
              >
                ${formatPrice(originalPrice)}
              </span>
              <span className="price" style={{ color: "green" }}>
                ${formatPrice(discountedPrice)}
              </span>
            </>
          ) : (
            <span className="price">${formatPrice(originalPrice)}</span>
          )}
          <span className="period"> /seat</span>
        </PriceTypography>
        {discount && discount.enable === "true" && (
          <>
            <Typography variant="h6" color="error">
              {discount.percentage}% off
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleUpdateClick}
              sx={{ mt: 1 }}
            >
              Update Discount
            </Button>
          </>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};
const Loader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-4 opacity-75 z-50">
      <div
        class="loader border-t-2 rounded-full border-gray-500 bg-gray-300 animate-spin
aspect-square w-16 flex justify-center items-center text-yellow-700"
      ></div>
    </div>
  );
};

const DiscountPage = () => {
  const { currentUser } = useAuth();
  const [discounts, setDiscounts] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [courseDiscounts, setCourseDiscounts] = useState({});
  const [bundleDiscounts, setBundleDiscounts] = useState({});
  const [bundlesData, setBundlesData] = useState([]);
  const [courseLoading, setCourseLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [discountDetails, setDiscountDetails] = useState({
    percentage: "",
  });

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateDiscountDetails, setUpdateDiscountDetails] = useState({
    resourceId: null,
    percentage: "",
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteModalOpen = () => {
    if (
      (!selectedCourses || selectedCourses.length === 0) &&
      (!selectedBundles || selectedBundles.length === 0)
    ) {
      toast.error("Select at least one course or bundle to delete discount");
      return;
    }

    setShowDeleteConfirmation(true);
  };

  const deleteDiscounts = async () => {
    setLoading(true); // Set loading to true when deleting discounts
    try {
      const nonDiscountedCourses = [];
      const nonDiscountedBundles = [];
      let successfulDeletions = 0;

      // Process courses
      for (const courseId of selectedCourses) {
        const response = await axiosInstance.delete("/discounts/delete", {
          data: { resourceId: courseId, resourceType: "course" },
        });
        if (!response.data.success) {
          if (
            response.data.message ===
            "No active discount found for the selected resource."
          ) {
            nonDiscountedCourses.push(courseId);
          } else {
            toast.error(
              `Failed to delete discount for course ${courseId}: ${response.data.message}`
            );
          }
        } else {
          successfulDeletions++;
        }
      }

      // Process bundles
      for (const bundleId of selectedBundles) {
        const response = await axiosInstance.delete("/discounts/delete", {
          data: { resourceId: bundleId, resourceType: "bundle" },
        });
        if (!response.data.success) {
          if (
            response.data.message ===
            "No active discount found for the selected resource."
          ) {
            nonDiscountedBundles.push(bundleId);
          } else {
            toast.error(
              `Failed to delete discount for bundle ${bundleId}: ${response.data.message}`
            );
          }
        } else {
          successfulDeletions++;
        }
      }

      // Show aggregated messages
      if (nonDiscountedCourses.length > 0) {
        toast.info(
          `${nonDiscountedCourses.length} course(s) did not have active discounts.`
        );
      }
      if (nonDiscountedBundles.length > 0) {
        toast.info(
          `${nonDiscountedBundles.length} bundle(s) did not have active discounts.`
        );
      }
      if (successfulDeletions > 0) {
        toast.success(
          `Successfully deleted ${successfulDeletions} discount(s).`
        );
      }

      getAllCourses();
      getAllBundles();
      fetchDiscounts();
      setShowDeleteConfirmation(false);
      setSelectedCourses([]);
      setSelectedBundles([]);
    } catch (error) {
      console.error("Error deleting discounts:", error);
      toast.error("An error occurred while deleting the discounts.");
    } finally {
      setLoading(false); // Set loading back to false after the process completes
    }
  };

  const handleUpdateModalOpen = (itemId, discount) => {
    setUpdateDiscountDetails({
      resourceId: itemId,
      percentage: discount.percentage,
    });
    setShowUpdateModal(true);
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateDiscountDetails((prev) => ({ ...prev, [name]: value }));
  };

  const updateDiscount = async () => {
    try {
      await axiosInstance.put("/discounts/update", {
        resourceId: updateDiscountDetails.resourceId,
        percentage: updateDiscountDetails.percentage,

        enable: "true",
        resourceType: selectedCourses.includes(updateDiscountDetails.resourceId)
          ? "course"
          : "bundle",
      });

      getAllCourses();
      getAllBundles();
      fetchDiscounts();
      toast.success("Discount updated");

      setShowUpdateModal(false);
    } catch (error) {
      console.error("Error updating discount:", error);
      toast.error("Failed to update discount");
    }
  };

  const handleModalOpen = () => {
    if (
      (!selectedCourses || selectedCourses.length === 0) &&
      (!selectedBundles || selectedBundles.length === 0)
    ) {
      toast.error("Select at least one course or bundle");
      return;
    }

    setShowModal(true);
  };

  const getAllCourses = async () => {
    try {
      setCourseLoading(true);
      const result = await axiosInstance.get("/courses/all");
      const courses = result.data.data;
      setCourseLoading(false);
      setCoursesData(courses);
    } catch (error) {
      setCourseLoading(false);
      console.log(error);
    }
  };
  const getAllBundles = async () => {
    try {
      const result = await axiosInstance.get("/courses/allbundles");
      const bundles = result.data.data;
      setBundlesData(bundles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCourses();
    getAllBundles();
  }, [currentUser.email]);

  const handleCourseSelection = (courseId) => {
    setSelectedCourses((prev) =>
      prev.includes(courseId)
        ? prev.filter((id) => id !== courseId)
        : [...prev, courseId]
    );
  };

  const handleBundleSelection = (bundleId) => {
    setSelectedBundles((prev) =>
      prev.includes(bundleId)
        ? prev.filter((id) => id !== bundleId)
        : [...prev, bundleId]
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiscountDetails((prev) => ({ ...prev, [name]: value }));
  };

  const fetchDiscounts = async () => {
    try {
      const response = await axiosInstance.get("/discounts/get");
      const allDiscounts = response.data.data;

      console.log("all discount", allDiscounts);

      const courseDiscountsMap = {};
      const bundleDiscountsMap = {};

      // biome-ignore lint/complexity/noForEach: <explanation>
      allDiscounts.forEach((discount) => {
        if (discount.resource_type === "course") {
          courseDiscountsMap[discount.resource_Id] = {
            percentage: discount.percentage,

            enable: discount.enable,
          };
        } else if (discount.resource_type === "bundle") {
          bundleDiscountsMap[discount.resource_Id] = {
            percentage: discount.percentage,

            enable: discount.enable,
          };
        }
      });

      setCourseDiscounts(courseDiscountsMap);
      setBundleDiscounts(bundleDiscountsMap);
    } catch (error) {
      setError(error.message);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchDiscounts(); // Call the function on component mount
  }, []);

  const handleToggleAllCourses = () => {
    if (selectedCourses.length === coursesData.length) {
      setSelectedCourses([]);
    } else {
      setSelectedCourses(coursesData.map((course) => course.id));
    }
  };

  const handleToggleAllBundles = () => {
    if (selectedBundles.length === bundlesData.length) {
      setSelectedBundles([]);
    } else {
      setSelectedBundles(bundlesData.map((bundle) => bundle.bundleId));
    }
  };

  const handleToggleAll = () => {
    const allSelected =
      selectedCourses.length === coursesData.length &&
      selectedBundles.length === bundlesData.length;

    if (allSelected) {
      setSelectedCourses([]);
      setSelectedBundles([]);
    } else {
      handleToggleAllCourses();
      handleToggleAllBundles();
    }
  };

  const applyDiscount = async () => {
    setLoading(true); // Set loading to true when applying discount

    const discountData = {
      percentage: discountDetails.percentage,

      enable: "true",
    };

    try {
      const existingDiscountedCourses = [];
      const existingDiscountedBundles = [];
      let successfulApplications = 0;

      // Process courses
      for (const courseId of selectedCourses) {
        const response = await axiosInstance.post("/discounts/add", {
          ...discountData,
          resourceId: courseId,
          resourceType: "course",
        });

        if (!response.data.success) {
          if (
            response.data.message ===
            "An active discount already exists for this resource."
          ) {
            existingDiscountedCourses.push(courseId);
          } else {
            toast.error(
              `Failed to add discount for course ${courseId}: ${response.data.message}`
            );
          }
        } else {
          successfulApplications++;
        }
      }

      // Process bundles
      for (const bundleId of selectedBundles) {
        const response = await axiosInstance.post("/discounts/add", {
          ...discountData,
          resourceId: bundleId,
          resourceType: "bundle",
        });

        if (!response.data.success) {
          if (
            response.data.message ===
            "An active discount already exists for this resource."
          ) {
            existingDiscountedBundles.push(bundleId);
          } else {
            toast.error(
              `Failed to add discount for bundle ${bundleId}: ${response.data.message}`
            );
          }
        } else {
          successfulApplications++;
        }
      }

      // Show aggregated messages
      if (existingDiscountedCourses.length > 0) {
        toast.info(
          `${existingDiscountedCourses.length} course(s) already have active discounts.`
        );
      }
      if (existingDiscountedBundles.length > 0) {
        toast.info(
          `${existingDiscountedBundles.length} bundle(s) already have active discounts.`
        );
      }
      if (successfulApplications > 0) {
        toast.success(
          `Successfully applied ${successfulApplications} discount(s).`
        );
      }

      getAllCourses();
      getAllBundles();
      fetchDiscounts();
      setShowModal(false);
    } catch (error) {
      console.error("Error applying discount:", error);
      toast.error("An error occurred while applying the discounts.");
    } finally {
      setLoading(false); // Set loading back to false after process completes
    }
  };
  // A11y props for accessibility
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const hasDiscountCourse = selectedCourses.some(
    (courseId) =>
      courseDiscounts[courseId] && courseDiscounts[courseId].enable === "true"
  );
  const hasDiscountBundle = selectedBundles.some(
    (bundleId) =>
      bundleDiscounts[bundleId] && bundleDiscounts[bundleId].enable === "true"
  );

  return (
    <div fullWidth>
      <Box display="flex" flexDirection="column" mb={1}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={1}
        >
          <Heading text="Discount Page" />
        </Box>
      </Box>

      <Box sx={{ width: "100%", mt: 1, mb: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#02496F",
              },
            }}
          >
            {["Special Discount", "Per Seat"].map((label, index) => (
              <Tab
                key={index}
                label={label}
                {...a11yProps(index)}
                sx={{
                  "&.Mui-selected": {
                    color: "#02496F",
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: "#23436d",
                    fontWeight: "bold",
                  },
                }}
              />
            ))}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          {courseLoading ? (
            <Loader />
          ) : (
            <div>
              <Box>
                {(selectedCourses.length > 0 || selectedBundles.length > 0) &&
                  !hasDiscountCourse &&
                  !hasDiscountBundle && (
                    <Tooltip title="Create Discount" placement="left">
                      <StyledFab onClick={handleModalOpen} aria-label="add">
                        <AddIcon />
                      </StyledFab>
                    </Tooltip>
                  )}

                {(hasDiscountCourse || hasDiscountBundle) && (
                  <Tooltip title="Delete Discount" placement="left">
                    <StyledIconButton
                      onClick={handleDeleteModalOpen}
                      aria-label="delete"
                      size="large"
                    >
                      <DeleteIcon fontSize="inherit" />
                    </StyledIconButton>
                  </Tooltip>
                )}
              </Box>
              {/* <div className="flex justify-between">
                <div>
                  <Typography variant="h4" gutterBottom>
                    Courses
                  </Typography>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    onClick={handleToggleAllCourses}
                    sx={{
                      mr: 2,
                      borderColor: "#23436d",
                      color: "#23436d",
                      "&:hover": {
                        borderColor: "#23436d",
                      },
                    }}
                  >
                    {selectedCourses.length === coursesData.length
                      ? "Deselect"
                      : "Select"}{" "}
                    All Courses
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleToggleAllBundles}
                    sx={{
                      mr: 2,
                      borderColor: "#23436d",
                      color: "#23436d",
                      "&:hover": {
                        borderColor: "#23436d",
                      },
                    }}
                  >
                    {selectedBundles.length === bundlesData.length
                      ? "Deselect"
                      : "Select"}{" "}
                    All Bundles
                  </Button>
                  <Button
                    sx={{
                      mr: 2,
                      borderColor: "#23436d",
                      color: "#23436d",
                      "&:hover": {
                        borderColor: "#23436d",
                      },
                    }}
                    variant="outlined"
                    onClick={handleToggleAll}
                  >
                    {selectedCourses.length === coursesData.length &&
                    selectedBundles.length === bundlesData.length
                      ? "Deselect"
                      : "Select"}{" "}
                    All
                  </Button>
                </div>
              </div> */}
              <Box>
                <Box className="flex justify-between items-center mb-4">
                  <Typography variant="h4" gutterBottom>
                    Courses
                  </Typography>
                  <Box>
                    <StyledButton onClick={handleToggleAllCourses}>
                      {selectedCourses.length === coursesData.length
                        ? "Deselect"
                        : "Select"}{" "}
                      All Courses
                    </StyledButton>
                    <StyledButton onClick={handleToggleAllBundles}>
                      {selectedBundles.length === bundlesData.length
                        ? "Deselect"
                        : "Select"}{" "}
                      All Bundles
                    </StyledButton>
                    <StyledButton onClick={handleToggleAll}>
                      {selectedCourses.length === coursesData.length &&
                      selectedBundles.length === bundlesData.length
                        ? "Deselect"
                        : "Select"}{" "}
                      All
                    </StyledButton>
                  </Box>
                </Box>
                <Alert
                  severity="info"
                  sx={{
                    backgroundColor: "rgba(0, 72, 111, 0.08)",
                    color: "#23436d",
                    "& .MuiAlert-icon": {
                      color: "#23436d",
                    },
                    fontSize: "20px",
                    marginBottom: 4,
                    display: "flex", // Use flexbox for alignment
                    alignItems: "center", // Center items vertically
                  }}
                >
                  <span className="MuiAlert-icon" />{" "}
                  {/* This will render the default icon */}
                  <span style={{ marginLeft: "8px" }}>
                    {" "}
                    {/* Add margin to the left of the text */}
                    Select a course or bundle to create a discount. You can
                    select multiple items to apply the discount to a group.
                  </span>
                </Alert>
              </Box>

              {/* Grid for courses */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {coursesData.map((course) => (
                  <Grid item xs={12} sm={6} md={4} key={course.id}>
                    <ItemCard
                      item={course}
                      isSelected={selectedCourses.includes(course.id)}
                      onSelect={handleCourseSelection}
                      discount={courseDiscounts[course.id]}
                      onUpdateDiscount={handleUpdateModalOpen}
                    />
                  </Grid>
                ))}
              </Grid>

              <Typography variant="h4" gutterBottom>
                Bundles
              </Typography>

              {/* Grid for bundles */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {bundlesData.map((bundle) => (
                  <Grid item xs={12} sm={6} md={4} key={bundle.bundleId}>
                    <ItemCard
                      item={bundle}
                      isSelected={selectedBundles.includes(bundle.bundleId)}
                      onSelect={handleBundleSelection}
                      discount={bundleDiscounts[bundle.bundleId]}
                      onUpdateDiscount={handleUpdateModalOpen}
                    />
                  </Grid>
                ))}
              </Grid>

              {/* Button container fixed to bottom-right of the viewport */}
              {/* <div className="fixed bottom-4 right-4">
                <Box>
                  {console.log(
                    "selected bundles and discounts",
                    selectedBundles,
                    bundleDiscounts
                  )}
                  {(selectedCourses.length > 0 || selectedBundles.length > 0) &&
                    !hasDiscountCourse &&
                    !hasDiscountBundle && (
                      <button
                        title="Add New"
                        onClick={() => handleModalOpen()}
                        className="group cursor-pointer outline-none hover:rotate-90 duration-300"
                        style={{
                          backgroundColor: "#23436d", // Background color
              
                          color: "white", // Text color
                          fontSize: "1.25rem", // Font size
                          transition: "background-color 0.3s", // Transition effect for background color
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50px"
                          height="50px"
                          viewBox="0 0 24 24"
                          className="stroke-blue-400 fill-none group-hover:fill-blue-800 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                        >
                          <path
                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                            strokeWidth="1.5"
                          ></path>
                          <path d="M8 12H16" strokeWidth="1.5"></path>
                          <path d="M12 16V8" strokeWidth="1.5"></path>
                        </svg>
                        <span className="absolute left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          Create Discount
                        </span>
                      </button>
                    )}

                  {(hasDiscountCourse || hasDiscountBundle) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDeleteModalOpen()}
                      sx={{
                        mr: 2,
                        bgcolor: "#00A3AE",
                        color: "white",
                        "&:hover": {
                          borderColor: "#23436d",
                        },
                      }}
                    >
                      Delete Discount
                    </Button>
                  )}
                </Box>
              </div> */}
            </div>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <SeatDiscountConfig />
        </TabPanel>
      </Box>

      <div>
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Create Discount
          </DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="percentage"
              label="Discount Percentage"
              type="number"
              fullWidth
              variant="outlined"
              value={discountDetails.percentage}
              onChange={handleInputChange}
              sx={{ marginBottom: "1.5rem" }}
            />
          </DialogContent>

          <DialogActions
            sx={{ padding: "0 1.5rem 1.5rem", justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => setShowModal(false)}
              variant="contained"
              sx={{
                color: "white",
                marginRight: "0.75rem",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={applyDiscount}
              variant="contained"
              disabled={loading}
              sx={{
                padding: "0.5rem 1.5rem",
                backgroundColor: "#02496F",
                "&:hover": {
                  backgroundColor: "#023A5A",
                },
              }}
            >
              {loading ? "Applying..." : "Apply Discount"}{" "}
              {/* Change text during loading */}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          maxWidth="sm"
          fullWidth
          open={showUpdateModal}
          onClose={() => setShowUpdateModal(false)}
        >
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Update Discount
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="percentage"
              label="Discount Percentage"
              type="number"
              fullWidth
              variant="outlined"
              value={updateDiscountDetails.percentage}
              onChange={handleUpdateInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                color: "white",
                padding: "0.5rem 1.5rem",
                marginRight: "0.75rem",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              onClick={() => setShowUpdateModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={updateDiscount}
              variant="contained"
              sx={{
                padding: "0.5rem 1.5rem",
                backgroundColor: "#02496F", // Custom blue color for Add
                "&:hover": {
                  backgroundColor: "#023A5A", // Slightly darker shade on hover
                },
              }}
            >
              Update Discount
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
        >
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete the selected discounts? This
              action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={deleteDiscounts}
              variant="contained"
              color="error"
            >
              {loading ? "Deleting..." : "Delete Discounts"}{" "}
              {/* Change text during loading */}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default DiscountPage;
