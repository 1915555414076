import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import courseicon from "../../../src/assets/course.png";
import { Link } from "react-router-dom";
import { BundleCard } from "../components/BundleCard";
import { Box, Chip, Grid, Tab, Tabs, Typography } from "@mui/material"; // Assuming you're using MUI
import { Loader } from "../../utils/Loader";
import {
  School as SchoolIcon,
  Collections as CollectionsIcon,
} from "@mui/icons-material";
import { FaCartPlus } from "react-icons/fa";
import Heading from "../../components/ui";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const BuyNewLicense = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [bundlesData, setBundlesData] = useState([]);
  const [courseDiscounts, setCourseDiscounts] = useState([]);
  const [bundleDiscounts, setBundleDiscounts] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getAllCourses = async () => {
    try {
      const result = await axiosInstance.get("/courses/all");
      const courses = result.data.data;
      setLoading(false);
      setCoursesData(courses);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllBundles = async () => {
    try {
      const result = await axiosInstance.get("/courses/allbundles");
      const bundles = result.data.data;
      setBundlesData(bundles);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDiscounts = async () => {
    try {
      const result = await axiosInstance.get("/discounts/get"); // Adjust endpoint as necessary
      const discounts = result.data.data;

      // Separate discounts based on resource type
      const courses = discounts.filter((d) => d.resource_type === "course");
      const bundles = discounts.filter((d) => d.resource_type === "bundle");

      setCourseDiscounts(courses);
      setBundleDiscounts(bundles);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourses();
    getAllBundles();
    getDiscounts(); // Fetch discounts on component mount
  }, []);
  return (
    <>
      <div className="w-full flex flex-col  justify-start items-center">
        <div className="w-[100%] flex flex-col gap-1 justify-center items-center">
          <div className="w-full flex flex-col justify-center items-start">
            <Heading text={"Shop"} />
          </div>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="courses and bundles tabs"
                variant={"standard"}
                // centered={!isMobile}
              >
                <Tab
                  label="Courses"
                  icon={<SchoolIcon />}
                  iconPosition="start"
                  sx={{
                    fontWeight: "bold",
                    // color: theme.palette.primary.main,
                    // '&.Mui-selected': {
                    //   color: theme.palette.primary.dark,
                    // }
                  }}
                />
                <Tab
                  label="Bundles"
                  icon={<CollectionsIcon />}
                  iconPosition="start"
                  sx={{
                    fontWeight: "bold",
                    // color: theme.palette.primary.main,
                    // '&.Mui-selected': {
                    //   color: theme.palette.primary.dark,
                    // }
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {loading && <Loader />}
              {!loading && (
                <>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    Courses
                  </Typography>
                  <Grid container spacing={2}>
                    {coursesData?.map((course, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={3}
                        key={index}
                      >
                        <Link
                          to={`/contrib_dashboard/course_details?id=${course.id}`}
                        >
                          <CourseCard
                            data={course}
                            discounts={courseDiscounts}
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Bundles
                </Typography>
                <Grid container spacing={2}>
                  {bundlesData?.map((bundle, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
                      <Link
                        to={`/contrib_dashboard/view_bundles?id=${bundle.bundleId}`}
                      >
                        <BundleCard data={bundle} discounts={bundleDiscounts} />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </>
            </TabPanel>
          </Box>
          {coursesData?.length === 0 && !loading && (
            <h2 className="text-black text-center font-bold text-1xl">
              No Courses Available!
            </h2>
          )}
        </div>
      </div>
    </>
  );
};

// 	// Find applicable discount for the course
// 	const discount = discounts.find(
// 		(d) => d.resource_Id === data.id && d.enable === "true",
// 	);

// 	const getOriginalPrice = () => parseFloat(data.priceperseat) || 0;
// 	const originalPrice = getOriginalPrice();
// 	const discountedPrice = discount
// 		? originalPrice * (1 - (parseFloat(discount.percentage) || 0) / 100)
// 		: originalPrice;

// 	const formatPrice = (price) => {
// 		return typeof price === "number" ? price.toFixed(2) : "0.00";
// 	};

// 	// Function to truncate the description to a specified length
// 	const truncateDescription = (description, maxLength) => {
// 		return description.length > maxLength
// 			? description.slice(0, maxLength) + "..."
// 			: description;
// 	};

// 	return (
// 		<div className="w-full bg-white border border-gray-200 rounded-lg shadow flex flex-col h-full">
// 			{/* Course Icon */}
// 			<img
// 				className="rounded-t-lg cursor-not-allowed w-full h-[200px] object-cover"
// 				src={courseicon}
// 				alt="Course Icon"
// 			/>

// 			{/* Course Info */}
// 			<div className="p-5 flex-1 flex flex-col justify-between">
// 				<div>
// 					<Typography variant="h5" component="h2" gutterBottom>
// 						{data.title}
// 					</Typography>

// 					{/* Truncated Description */}
// 					<Typography variant="body2" color="textSecondary" className="mb-4">
// 						{truncateDescription(data.description, 100)} {/* Set max length */}
// 					</Typography>
// 					{discount && (
// 						<Chip
// 							label={`${discount.percentage}% OFF`}
// 							color="error"
// 							size="medium"
// 							sx={{ mb: 1, mt: 1 }}
// 						/>
// 					)}
// 					{/* Price */}
// 					<Typography variant="body1" className="mb-2">
// 						{discount ? (
// 							<>
// 								<span
// 									style={{
// 										textDecoration: "line-through",
// 										color: "gray",
// 										marginRight: "10px",
// 									}}
// 								>
// 									${formatPrice(originalPrice)}
// 								</span>
// 								<span style={{ color: "green" }}>
// 									${formatPrice(discountedPrice)}
// 								</span>
// 							</>
// 						) : (
// 							<span>${formatPrice(originalPrice)}</span>
// 						)}{" "}
// 						/ seat
// 					</Typography>

// 					{/* Discount Information */}
// 				</div>

// 				{/* Add to Cart Button */}
// 				<div className="w-full flex justify-center items-end">
// 					<Link to={`/contrib_dashboard/mycart?course_id=${data.id}`}>
// 						<div className="cursor-pointer inline-flex items-center px-5 py-2 text-md font-bold text-center text-white bg-[#02496F] rounded-[22px] hover:bg-blue-800">
// 							Add to Cart
// 						</div>
// 					</Link>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };
export const CourseCard = ({ data, discounts }) => {
  const discount = discounts.find(
    (d) => d.resource_Id === data.id && d.enable === "true"
  );

  const getOriginalPrice = () => parseFloat(data.priceperseat) || 0;
  const originalPrice = getOriginalPrice();
  const discountedPrice = discount
    ? originalPrice * (1 - (parseFloat(discount.percentage) || 0) / 100)
    : originalPrice;

  const formatPrice = (price) => {
    return typeof price === "number" ? price.toFixed(2) : "0.00";
  };

  const truncateDescription = (description, maxLength) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <div className="w-full bg-white border  border-gray-200 rounded-lg shadow flex flex-col h-full">
      {/* Course Icon */}
      <img
        className="rounded-t-lg no-round  w-full h-[200px] object-cover"
        src={courseicon}
        alt="Course Icon"
      />

      {/* Course Info */}
      <div className="p-5 flex-1 flex flex-col justify-between">
        <div>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            className="truncate"
            style={{
              maxHeight: "2.6em",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.title}
          </Typography>

          {/* Truncated Description */}
          <Typography
            variant="body2"
            color="textSecondary"
            className="mb-4 line-clamp-3"
            style={{
              maxHeight: "4.5em", // Allows for 3 lines of text
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {truncateDescription(data?.description, 100)}
          </Typography>

          {/* Discount Badge */}
          {discount && (
            <Chip
              label={`${discount?.percentage}% OFF`}
              color="error"
              size="medium"
              sx={{ mb: 1, mt: 1 }}
            />
          )}

          {/* Price */}
        </div>

        <div className="w-full flex justify-between items-end">
          <div className=" flex items-end">
            {discount ? (
              <>
                <div className="flex flex-col">
                  <span
                    className=" text-md"
                    style={{
                      textDecoration: "line-through",
                      color: "gray",
                      marginRight: "10px",
                    }}
                  >
                    ${formatPrice(originalPrice)}
                  </span>
                  <span className=" text-3xl " style={{ color: "green" }}>
                    ${formatPrice(discountedPrice)}
                  </span>
                </div>
              </>
            ) : (
              <p className=" text-3xl text-[#02496F]">
                ${formatPrice(originalPrice)}
              </p>
            )}{" "}
            <span className={`ml-1 text-sm ${discount ? "pb-0" : "pb-1"}`}>
              / seat
            </span>
          </div>
          <div>
            <Link
              className=" cursor-pointer"
              to={`/contrib_dashboard/mycart?course_id=${data?.id}`}
            >
              <FaCartPlus className=" text-3xl text-[#02496F] no cursor-pointer animate-bounce hover:animate-none " />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNewLicense;
