import React, { useEffect, useState } from "react";
import UserReportTable from "../components/UserReportTable";
import CampaignReportTable from "../components/CampaignReportTable";
import ModuleReportTable from "../components/ModuleReportTable";
import { FaChevronDown } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { useAuth } from "../../AuthContext";
import axiosInstance from "../../utils/axiosConfig";
import { FaUsersLine } from "react-icons/fa6";
import { MdAutoGraph, MdLibraryBooks } from "react-icons/md";
import { ReportsSection } from "../../components/ReportSection";
import { GoPhishAccountAPIKey, GoPhishPublicURL } from "../../utils/constants";

const DashboardPage = () => {
  const { currentUser } = useAuth();
  const email = currentUser?.email;
  const [activeButton, setActiveButton] = useState("campaign");
  const [trainingOverviewOption, setTrainingOverviewOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [numOfUsers, setNumOfUsers] = useState("");
  const [numOfGroups, setNumOfGroups] = useState("");
  const [numOfCampaigns, setNumOfCampaigns] = useState("");
  const [AllGroups, setAllGroups] = useState([]);
  const [SelectedGroupName, setSelectedGroupName] = useState("");
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [searchGroupTerm, setSearchGroupTerm] = useState("");
  const [download, setDownload] = useState("");
  const fetchAllGroups = async () => {
    try {
      const resp = await axiosInstance.get(`/groups/all`);
      setAllGroups(resp.data.groups);
    } catch (error) {
      console.log("Error fetching all groups!");
    }
  };

  useEffect(() => {
    fetchAllGroups();
  }, [currentUser]);

  const filteredGroups = AllGroups.filter((group) =>
    group.name.toLowerCase().includes(searchGroupTerm.toLowerCase())
  );
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "training") {
      setTrainingOverviewOption("Module Report"); // Set default selection for training overview
    }
  };

  const handleDropdownSelection = (option) => {
    setTrainingOverviewOption(option);
    setIsDropdownOpen(false);
    setSearchTerm(""); // Reset search term when an option is selected
  };

  const handleClearSelection = () => {
    setTrainingOverviewOption(null);
  };

  const filteredOptions = ["User Report", "Module Report"].filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setActiveButton("campaign");
  }, []);

  const getReports = async () => {
    try {
      const resp = await axiosInstance.post(`/modules_report/stats`, {
        email,
      });

      setNumOfUsers(resp?.data?.data?.total_users_in_groups);
      setNumOfGroups(resp?.data?.data?.total_groups);
    } catch (error) {
      console.error("Error fetching reports data:", error.message);
    } finally {
    }
  };

  useEffect(() => {
    if (email) {
      getReports();
      getCampaignDataSummary();
    }
  }, []);
  const getCampaignDataSummary = async () => {
    try {
      const response = await fetch(
        `${GoPhishPublicURL}/api/campaigns/summary/?api_key=${GoPhishAccountAPIKey}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      let filteredCampaigns = data.campaigns;

      filteredCampaigns.forEach((campaign) => {
        const emailPattern = /, email: [^,]+/;
        const groupsPattern = /, groups: .*/;

        campaign.name = campaign.name
          .replace(emailPattern, "")
          .replace(groupsPattern, "")
          .trim();
      });

      const inProgressCount = filteredCampaigns.filter(
        (campaign) => campaign.status === "In progress"
      ).length;

      setNumOfCampaigns(inProgressCount);
    } catch (error) {
      console.error("Error fetching campaign data summary:", error);
    }
  };

  const handleGroupChange = (group) => {
    setSelectedGroupName(group.name);

    setIsGroupOpen(false);

    return group.name;
  };

  const handleGroupClear = () => {
    setSelectedGroupName(null);
  };

  return (
    <>
      <ReportsSection
        numOfUsers={numOfUsers}
        numOfGroups={numOfGroups}
        numOfcampaigns={numOfCampaigns}
        groupleader={false}
      />
      <div className="w-full h-auto flex flex-row justify-start items-start gap-3 mb-6">
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === "campaign"
              ? "bg-gray-300 border-0"
              : "border-[1px] border-gray-300"
          }`}
          onClick={() => handleButtonClick("campaign")}
        >
          Campaign Report
        </button>
        <button
          className={`px-4 py-2 font-semibold text-gray-500 rounded-lg ${
            activeButton === "training"
              ? "bg-gray-300 border-0"
              : "border-[1px] border-gray-300"
          }`}
          onClick={() => handleButtonClick("training")}
        >
          Training Overview
        </button>
      </div>

      <div className="w-full flex items-center bg-[#e1ecf0] rounded-t-lg text-gray-700 font-medium p-2">
        {/* Group Filter */}
        <div className="relative flex items-center mr-4">
          <button
            className="bg-white border border-gray-300 rounded-full px-3 py-2 text-left font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
            onClick={() => setIsGroupOpen(!isGroupOpen)}
          >
            {SelectedGroupName || "Select Group"}
            <FaChevronDown className="inline-block ml-2 h-4 w-4 text-gray-400" />
          </button>
          {SelectedGroupName && (
            <button
              className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              onClick={handleGroupClear}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
          {isGroupOpen && (
            <div className="absolute top-[3rem] z-10 mt-2 w-64 bg-white border border-gray-300 rounded-md shadow-lg">
              {/* Dropdown content */}
              <div className="p-2">
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                  placeholder="Search groups..."
                  value={searchGroupTerm}
                  onChange={(e) => setSearchGroupTerm(e.target.value)}
                />
              </div>
              <ul className="max-h-48 overflow-auto">
                {filteredGroups.map((group) => (
                  <li
                    key={group.id}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleGroupChange(group)}
                  >
                    {group.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* Training Overview Filter */}
        {activeButton === "training" && (
          <div className="relative flex items-center">
            <button
              className="bg-white border border-gray-300 rounded-full px-3 py-2 text-left font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {trainingOverviewOption || "Select View"}
              <FaChevronDown className="inline-block ml-2 h-4 w-4 text-gray-400" />
            </button>
            {trainingOverviewOption && (
              <button
                className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={handleClearSelection}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
            {isDropdownOpen && (
              <div className="absolute top-[3rem] z-10 w-64 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                <ul className="max-h-48 overflow-auto">
                  {filteredOptions.map((option) => (
                    <li
                      key={option}
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handleDropdownSelection(option);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {/* Download Excel Button */}
        {activeButton === "training" &&
          trainingOverviewOption === "User Report" && (
            <button
              style={{
                borderRadius: "20px",
              }}
              onClick={() => setDownload("download")}
              className="ml-auto bg-[#299aa1] text-white px-4  py-2 rounded-lg hover:bg-[#227b82]"
            >
              Download Excel
            </button>
          )}{" "}
      </div>

      <div>
        {activeButton === "campaign" && (
          <CampaignReportTable SelectedGroupName={SelectedGroupName} />
        )}
        {activeButton === "training" && (
          <div>
            {/* Render the corresponding table based on the dropdown selection */}
            {trainingOverviewOption === "User Report" && (
              <UserReportTable
                setDownload={setDownload}
                download={download}
                SelectedGroupName={SelectedGroupName}
              />
            )}
            {trainingOverviewOption === "Module Report" && (
              <ModuleReportTable SelectedGroupName={SelectedGroupName} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardPage;
