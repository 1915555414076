export const Loader = () => {
	return (
		<div className="w-full h-full flex items-center justify-center mt-4 opacity-75 z-50">
			<div
				className="loader border-t-2 rounded-full border-[#02496F] bg-gray-300 animate-spin
  aspect-square w-16 flex justify-center items-center text-yellow-700"
			></div>
		</div>
	);
};
