import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Chip, Paper, styled, Typography } from "@mui/material";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaPercentage,
  FaUsers,
} from "react-icons/fa";
import { CiDiscount1 } from "react-icons/ci";
const DiscountPaper = styled(Paper)(({ theme, isActive }) => ({
  padding: theme.spacing(2),
  borderRadius: "12px",
  transition: "all 0.3s ease",
  maxWidth: "208px",
  flex: "1 1 30%",
  margin: theme.spacing(1),

  border: isActive ? `2px solid #02496F` : "none",
  backgroundColor: isActive ? "#f0f8ff" : "white",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));
const DiscountGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  "& > svg": {
    marginRight: theme.spacing(1),
  },
}));
const MyBundleCart = () => {
  const [bundleId, setBundleId] = useState(null);
  const [bundleDetails, setBundleDetails] = useState({});
  const [coursesDetails, setCoursesDetails] = useState([]);

  const [discountDetails, setDiscountDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [seatDiscounts, setSeatDiscounts] = useState([]);
  const [seatDiscount, setSeatDiscount] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const fetchDiscounts = async () => {
    try {
      const response = await axiosInstance.get("/seats_discounts/get");
      if (response.data.success) {
        setSeatDiscounts(response.data.data);
      } else {
        console.error("Failed to fetch discounts:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching discounts:", error);
    }
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const calculateSeatDiscount = () => {
    const totalSeats = bundleDetails.quantity; // Directly access quantity

    const applicableDiscount = seatDiscounts
      .sort((a, b) => b.seats - a.seats) // Sort discounts in descending order of seats
      .find((discount) => totalSeats >= discount.seats);

    if (applicableDiscount) {
      const discountAmount =
        (bundleDetails.subtotal * applicableDiscount.percentage) / 100;
      setSeatDiscount(discountAmount);
    } else {
      setSeatDiscount(0);
    }
  };

  useEffect(() => {
    calculateSeatDiscount();
  }, [bundleDetails]);
  const totalSeats = bundleDetails.quantity;

  useEffect(() => {
    const calculateAppliedDiscount = () => {
      const applicableDiscount = seatDiscounts
        .sort((a, b) => b.seats - a.seats) // Sort by seats in descending order
        .find((discount) => totalSeats >= discount.seats);

      setAppliedDiscount(applicableDiscount || null);
    };

    calculateAppliedDiscount();
  }, [seatDiscounts, totalSeats]);

  useEffect(() => {
    const bundleIdFromURL = new URLSearchParams(window.location.search).get(
      "bundleId"
    );
    if (!bundleIdFromURL) {
      setIsCartEmpty(true);
    } else {
      setBundleId(bundleIdFromURL);
    }
  }, []);

  // Fetch bundle and course details
  useEffect(() => {
    if (bundleId) {
      fetchBundleAndCourseDetails(bundleId);
    }
  }, [bundleId]);

  // Fetch bundle and associated course details
  const fetchBundleAndCourseDetails = async (bundleId) => {
    try {
      const bundleResponse = await axiosInstance.get(
        `/courses/bundles/${bundleId}`
      );
      const bundleData = bundleResponse.data.data;
      bundleData.quantity = 1;
      bundleData.subtotal = bundleData.seatprice;

      setBundleDetails(bundleData);
      setSubtotal(bundleData.subtotal);

      const coursesResponse = await axiosInstance.get(
        `/courses/bundles_courses/${bundleId}`
      );
      const courseIds = coursesResponse.data.course_ids;

      // Fetch course details for each course ID
      const courseDetailsResponses = await Promise.all(
        courseIds.map((id) => axiosInstance.get(`/courses/${id}`))
      );
      const courses = courseDetailsResponses.map(
        (response) => response.data.data
      );

      setCoursesDetails(courses);

      // Fetch any applicable discounts
      fetchDiscountDetails(bundleId, bundleData);
    } catch (error) {
      console.error("Error fetching bundle and course details:", error);
    }
  };

  // Fetch discount details and update bundle subtotal
  const fetchDiscountDetails = async (bundleId, bundleData) => {
    try {
      const discountResponse = await axiosInstance.post(
        "/discounts/getdiscount",
        {
          resource_id: bundleId,
          resource_type: "bundle",
        }
      );

      if (discountResponse.data.success) {
        const discount = discountResponse.data.data;
        setDiscountDetails(discount);

        const discountedSubtotal = applyDiscount(
          bundleData.quantity * bundleData.seatprice,
          discount.percentage
        );
        bundleData.subtotal = discountedSubtotal;

        setBundleDetails(bundleData);
        setSubtotal(discountedSubtotal);
      }
    } catch (error) {
      console.error("Error fetching discount details:", error);
    }
  };

  // Apply discount to subtotal if available
  const applyDiscount = (subtotal, discountPercentage) => {
    if (discountPercentage) {
      const discountAmount = (subtotal * discountPercentage) / 100;
      return subtotal - discountAmount;
    }
    return subtotal;
  };

  // Update the bundle quantity and recalculate the subtotal
  const handleUpdateQuantity = (updatedQuantity) => {
    const updatedBundle = { ...bundleDetails };
    updatedBundle.quantity = updatedQuantity;

    const newSubtotal = applyDiscount(
      updatedQuantity * updatedBundle.seatprice,
      discountDetails?.percentage
    );
    updatedBundle.subtotal = newSubtotal;

    setBundleDetails(updatedBundle);
    setSubtotal(newSubtotal);
  };

  // Handle bundle purchase
  const handlePurchase = async () => {
    try {
      const totalQuantity = bundleDetails.quantity;

      if (totalQuantity > 0) {
        await axiosInstance.post(
          `/courses/purchase_bundle_seats/${bundleId}/${totalQuantity}`,
          { buyer: currentUser }
        );
        toast.success("Purchase successful! Redirecting...");
        setTimeout(() => navigate("/dashboard/mycart"), 3000);
      } else {
        toast.error("No courses selected.");
      }
    } catch (error) {
      console.error(
        "Error during purchase:",
        error.response?.data || error.message
      );
    }
  };

  // const handlePurchase = async () => {
  // 	try {
  // 		const totalQuantity = bundleDetails.quantity;

  // 		if (totalQuantity > 0) {
  // 			// Make API call to purchase the bundle
  // 			await axiosInstance.post(
  // 				`/courses/purchase_bundle_seats/${bundle_Id}/${totalQuantity}`,
  // 				{
  // 					buyer: currentUser,
  // 				},
  // 			);
  // 			toast.success("Purchase Successful! Redirecting...");
  // 			setTimeout(() => {
  // 				navigate("/dashboard/mycart");
  // 			}, 3000);
  // 		} else {
  // 			toast.error("No courses selected.");
  // 		}
  // 	} catch (error) {
  // 		console.error(
  // 			"Error purchasing bundle:",
  // 			error.response ? error.response.data : error.message,
  // 		);
  // 	}
  // };

  return (
    <>
      <div className="w-full flex flex-col  justify-start items-center">
        <div className="w-[100%] flex flex-col justify-center items-center">
          <div className="w-full flex flex-col my-3 justify-center items-start">
            <h1 className="text-[#23436d] text-start font-bold text-[30px] mt-5 mb-5">
              Bundle Cart
            </h1>
          </div>
          <div className="w-full flex flex-col justify-start mt-5 bg-[#299aa1] rounded-3xl">
            <div className="flex">
              <h1 className="text-white items-start flex justify-start mt-4 text-3xl ml-4">
                <CiDiscount1 style={{ marginRight: "8px", marginTop: "4px" }} />
                Discount offers all year round!
              </h1>
            </div>
            <DiscountGrid>
              {seatDiscounts.map((discount) => (
                <DiscountPaper
                  key={discount.id}
                  isActive={appliedDiscount?.id === discount.id}
                >
                  <IconWrapper>
                    <FaUsers />
                    <Typography variant="body2" color="text.secondary">
                      {discount.seats}+ seats
                    </Typography>
                  </IconWrapper>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ fontWeight: "semibold" }}
                  >
                    {discount.percentage}% Off
                  </Typography>
                  {appliedDiscount?.id === discount.id && (
                    <Chip
                      icon={<FaCheckCircle size={16} />}
                      label="Applied"
                      color="primary"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  )}
                </DiscountPaper>
              ))}
            </DiscountGrid>
          </div>

          {!isCartEmpty ? (
            <>
              <div className="w-full flex flex-col justify-center items-center bg-white">
                {/* Bundle Info */}
                <div className="w-full py-3 grid grid-cols-4 justify-center items-center gap-1">
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">Bundle</p>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">Price</p>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">
                      Quantity
                    </p>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">
                      Sub Total
                    </p>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-slate-400"></div>

                {/* Bundle Seat Info */}

                <div className="w-full py-3 grid grid-cols-4 justify-center items-center gap-1">
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">
                      {bundleDetails.name}
                    </p>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">
                      {/* Conditionally render original and discounted price */}
                      {discountDetails?.percentage ? (
                        <>
                          {/* Original price with strikethrough */}
                          <span className="line-through text-red-500">
                            ${bundleDetails.seatprice}
                          </span>
                          {/* Discounted price */}
                          <span className="text-green-600 ml-2">
                            $
                            {bundleDetails.seatprice -
                              (bundleDetails.seatprice *
                                discountDetails.percentage) /
                                100}
                          </span>
                          / Seat
                        </>
                      ) : (
                        // If no discount, show the regular price
                        <>${bundleDetails.seatprice} / Seat</>
                      )}
                    </p>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <QuantityCounter
                      onChange={(quantity) => handleUpdateQuantity(quantity)}
                      initialValue={bundleDetails.quantity || 1}
                    />
                  </div>
                  <div className="w-full flex flex-col justify-center items-center">
                    <p className="font-semibold text-md text-center">
                      {/* Conditionally render the subtotal with discount */}
                      {discountDetails?.percentage ? (
                        <>
                          {/* Original subtotal with strikethrough */}
                          <span className="line-through text-red-500">
                            ${bundleDetails.quantity * bundleDetails.seatprice}
                          </span>

                          {/* Discounted subtotal */}
                          <span className="text-green-600 ml-2">
                            ${bundleDetails.subtotal}
                          </span>
                        </>
                      ) : (
                        // If no discount, show the regular subtotal
                        <>${bundleDetails.subtotal || 0}</>
                      )}
                    </p>
                  </div>
                </div>

                <div className="w-full h-[2px] bg-slate-400"></div>

                {/* Course List */}
                <div className="w-full py-6">
                  <h2 className="font-semibold text-2xl text-left mb-6">
                    Courses in Bundle
                  </h2>

                  {coursesDetails && coursesDetails.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {coursesDetails.map((item, index) => (
                        <div
                          key={index}
                          className="bg-white rounded-lg shadow-lg p-4 transition-transform transform hover:scale-105"
                        >
                          <div className="flex flex-col h-full">
                            <h3 className="font-semibold text-lg text-gray-800">
                              {item.title}
                            </h3>
                            <p className="text-sm text-gray-600 mt-1">
                              {item.description.length > 70
                                ? `${item.description.slice(0, 70)}...`
                                : item.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500 text-center">
                      No courses available.
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full h-[2px] bg-slate-400"></div>

              {/* Subtotal and Checkout */}
              <div className="w-full grid grid-cols-2 py-5 px-[90px] justify-center items-center bg-white">
                <div className="w-full flex flex-col justify-center items-center"></div>
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="w-full flex flex-row justify-between items-center">
                    <p className="text-lg font-normal text-gray-800">
                      SUBTOTAL
                    </p>
                    <p className="text-lg font-semibold text-black">
                      ${subtotal}
                    </p>
                  </div>
                  <div className="w-full flex flex-row justify-between items-center">
                    <p className="text-lg font-normal text-gray-800">VAT</p>
                    <p className="text-lg font-semibold text-black">{"N/A"}</p>
                  </div>
                  <div className="w-full flex flex-row justify-between items-center">
                    <p className="text-lg font-normal text-gray-800">
                      SEAT DISCOUNT
                    </p>
                    <p className="text-lg font-semibold text-black">
                      -${seatDiscount.toFixed(2)}
                    </p>
                  </div>
                  <div className="w-full flex flex-row justify-between items-center">
                    <p className="text-lg font-normal text-gray-800">TOTAL</p>
                    <p className="text-lg font-semibold text-black">
                      {(subtotal - seatDiscount).toFixed(2)}
                    </p>
                  </div>
                  <div
                    onClick={handlePurchase}
                    className="cursor-pointer inline-flex items-center px-5 py-2 text-md font-bold text-center text-white bg-[#299aa1] rounded-[22px] hover:bg-[#00A3AE]"
                  >
                    Proceed To Checkout
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="italic font-bold text-1xl">Cart is Empty.</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const QuantityCounter = ({ onChange, initialValue }) => {
  const [value, setValue] = useState(initialValue);
  const min = 1;
  const max = 1000;

  const handleDecrement = () => {
    if (value !== 1) {
      setValue((prevValue) => (prevValue > min ? prevValue - 1 : prevValue));
      onChange(value - 1);
    }
  };

  const handleIncrement = () => {
    setValue((prevValue) => (prevValue < max ? prevValue + 1 : prevValue));
    onChange(value + 1);
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <div className="relative flex items-center max-w-[8rem]">
      <button
        type="button"
        id="decrement-button"
        data-input-counter-decrement="bedrooms-input"
        className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        onClick={handleDecrement}
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 2"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h16"
          />
        </svg>
      </button>
      <input
        type="text"
        id="bedrooms-input"
        data-input-counter
        data-input-counter-min="1"
        data-input-counter-max="5"
        aria-describedby="helper-text-explanation"
        className="bg-gray-50 border-x-0 border-gray-300 h-11 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder=""
        value={value}
        onChange={handleChange}
        required
      />
      <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
        <span>Seats</span>
      </div>
      <button
        type="button"
        id="increment-button"
        data-input-counter-increment="bedrooms-input"
        className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
        onClick={handleIncrement}
      >
        <svg
          className="w-3 h-3 text-gray-900 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
      </button>
    </div>
  );
};

export default MyBundleCart;
