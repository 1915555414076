import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  Book as BookIcon,
  Collections as CollectionsIcon,
} from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../utils/axiosConfig";
import { MaterialReactTable } from "material-react-table";
import Box from "@mui/material/Box";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom";
import Heading from "../../components/ui";

const UploadCourse = () => {
  const [createCourseModalOpen, setCreateCourseModalOpen] = useState(false);
  const [createCourseBundleModalOpen, setCreateCourseBundleModalOpen] =
    useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const [bundlesData, setBundlesData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [setshowError, setSetshowError] = useState(false);
  const [enableRowSelection, setEnableRowSelection] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCourse = () => {
    handleClose();
    handleCreateCourseModal();
  };

  const handleAddBundle = () => {
    handleClose();
    handleCreateCourseBundleModal();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue); // Update the active tab
  };
  const selectedIds = Object.keys(rowSelection).filter(
    (key) => rowSelection[key] === true
  );
  // const handleCreateCourseBundleModal = () => {
  // 	if (selectedIds.length < 3) {
  // 		toast.error("Select atleast 3 course to Create Bundle");
  // 		return;
  // 	}
  // 	setCreateCourseBundleModalOpen(!createCourseBundleModalOpen);
  // };
  const [tableKey, setTableKey] = useState(0);
  const handleCreateCourseBundleModal = () => {
    if (Object.keys(rowSelection).length < 3) {
      setSetshowError(true);
      setTableKey((prevKey) => prevKey + 1);
      setEnableRowSelection(true);

      // toast.error("Select at least 3 courses to create a bundle");
      return;
    }
    setCreateCourseBundleModalOpen(!createCourseBundleModalOpen);
  };
  // useEffect(() => {
  //   if (Object.keys(rowSelection).length > 2) {
  //     setSetshowError(false);
  //     // toast.error("Select at least 3 courses to create a bundle");
  //   }
  // }, [rowSelection]);
  // useEffect(() => {
  //   setEnableRowSelection(true);
  // }, [enableRowSelection]);

  const handleCreateCourseModal = () => {
    setCreateCourseModalOpen(!createCourseModalOpen);
  };

  const [createCourseForm, setCreateCourseForm] = useState({
    title: "",
    description: "",
    priceperseat: "",
  });

  const [createCourseBundleForm, setCreateCourseBundleForm] = useState({
    title: "",
    description: "",
    priceperseat: "",
  });
  const getAllCourses = async () => {
    try {
      const result = await axiosInstance.get("/courses/all");
      const courses = result.data.data;
      setCoursesData(courses);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllBundles = async () => {
    try {
      const result = await axiosInstance.get("/courses/allbundles");
      const bundles = result.data.data;
      setBundlesData(bundles);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCourse = async () => {
    try {
      await axiosInstance.post("/courses/add", {
        title: createCourseForm.title,
        description: createCourseForm.description,
        priceperseat: createCourseForm.priceperseat,
      });
      toast.success("Course created successfully.");
      handleCreateCourseModal();
      setCreateCourseForm({
        title: "",
        description: "",
        priceperseat: "",
      });
      getAllCourses();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCreateCourseBundle = async () => {
    try {
      await axiosInstance.post("/courses/addbundle", {
        bundleName: createCourseBundleForm.title,
        bundleDescription: createCourseBundleForm.description,
        seatprice: createCourseBundleForm.priceperseat,
        courseIds: selectedIds,
      });
      toast.success("Course bundle created successfully.");
      handleCreateCourseBundleModal();
      setCreateCourseBundleForm({
        bundleName: "",
        bundleDescription: "",
      });
      setRowSelection({});
    } catch (error) {
      console.error("Error creating course bundle:", error);
      toast.error("Failed to create course bundle.");
    }
  };

  useEffect(() => {
    getAllCourses();
    getAllBundles();
  }, []);

  const BundleColumns = useMemo(
    () => [
      {
        accessorKey: "bundleId",
        header: "Id",
        size: 100,
        muiTableHeadCellProps: {
          sx: { paddingLeft: "18px" }, // Add padding to the header cell
        },
        Cell: ({ cell }) => (
          <Box sx={{ paddingLeft: "18px" }}>
            {" "}
            {/* Add padding here */}
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "name",
        header: "Title",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "seatprice",
        header: "$ / Seat",
        size: 100,
        Cell: ({ cell }) => <Box>{"$" + cell.getValue()}</Box>,
      },
      {
        header: "Details",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            <Link
              to={`/dashboard/view_bundles?id=${cell.row.original.bundleId} ${
                isAdmin ? "&admin=true" : ""
              }`}
            >
              <Button
                sx={{
                  borderColor: "#23436d", // Custom border color
                  color: "white", // Make the text color match the border if needed
                  "&:hover": {
                    bgcolor: "#00A3AE", // Change border color on hover
                  },
                  bgcolor: "#299aa1",
                }}
              >
                <FaLocationArrow />
              </Button>
            </Link>
          </Box>
        ),
      },
    ],
    []
  );
  const isAdmin = true;
  const CourseColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        size: 100,
        muiTableHeadCellProps: {
          sx: { paddingLeft: "18px" }, // Add padding to the header cell
        },
        Cell: ({ cell }) => (
          <Box sx={{ paddingLeft: "18px" }}>
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue().length > 50
              ? cell.getValue().slice(0, 50) + "..."
              : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "priceperseat",
        header: "$ / Seat",
        size: 100,
        Cell: ({ cell }) => <Box>{"$" + cell.getValue()}</Box>,
      },
      {
        header: "Details",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            <Link
              to={`/dashboard/course_details?id=${cell.row.original.id} ${
                isAdmin ? "&admin=true" : ""
              }`}
            >
              <Button
                sx={{
                  borderColor: "#23436d", // Custom border color
                  color: "white", // Make the text color match the border if needed
                  "&:hover": {
                    bgcolor: "#00A3AE", // Change border color on hover
                  },
                  bgcolor: "#299aa1",
                }}
              >
                <FaLocationArrow />
              </Button>
            </Link>
          </Box>
        ),
      },
    ],
    []
  );
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <>
      <div className="w-full flex flex-col justify-start items-center">
        {/* <div className="w-[90%] flex flex-row gap-5 justify-between items-center">
					<h1 className="text-[#23436d] font-bold text-[30px] mt-5 mb-5">
						Resource Center
					</h1>
					<Button variant="outlined" onClick={handleCreateCourseModal}>
						Add Course
					</Button>

					<Button
						variant="outlined"
						color="primary"
						onClick={handleCreateCourseBundleModal}
					>
						Add Bundle
					</Button>
				</div> */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Heading text="Resource Center" />
          <Box>
            <Button
              variant="contained"
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
              sx={{
                // bgcolor: "#299aa1",
                fontWeight: "bold",
                textTransform: "none",
                px: 3,
                py: 1,
                borderRadius: 2,
                bgcolor: "#299aa1",
                boxShadow: 2,
                "&:hover": {
                  bgcolor: "#00A3AE",
                  boxShadow: 4,
                },
              }}
            >
              Create
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleAddCourse}>
                <ListItemIcon>
                  <BookIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Course</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleAddBundle}>
                <ListItemIcon>
                  <CollectionsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add Bundle</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {/* <div className="flex flex-col justify-center items-start gap-1 w-[90%]">
					<h1 className="text-black font-bold text-[25px] mt-5 mb-5">
						All Courses
					</h1>
					<p className="font-semibold">{coursesData?.length} Course(s)</p>
					<div className="w-full">
						{coursesData && (
							<MaterialReactTable
								columns={CourseColumns}
								data={coursesData}
								initialState={{ density: "compact" }}
								enableRowSelection
								onRowSelectionChange={setRowSelection}
								state={{ rowSelection }}
								getRowId={(originalRow) => originalRow.id}
								muiTopToolbarProps={{
									sx: {
										backgroundColor: "#23436d",
										color: "#FFFFFF",
										"& .MuiSvgIcon-root": {
											color: "#FFFFFF",
										},
										"& .MuiButtonBase-root": {
											color: "#FFFFFF",
										},
									},
								}}
								muiTablePaperProps={{
									sx: {
										borderRadius: "12px",
										overflow: "hidden",
									},
								}}
								muiTablePaginationProps={{
									sx: {
										backgroundColor: "#23436d",
										color: "#FFFFFF",
										"& .MuiSvgIcon-root": {
											color: "#FFFFFF",
										},
										"& .MuiButtonBase-root": {
											color: "#FFFFFF",
										},
									},
								}}
							/>
						)}
					</div>
				</div> */}

        <div className="flex flex-col justify-center items-start gap-1 w-[100%]">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#02496F",
              },
            }}
          >
            {["Courses", "Bundles"].map((label, index) => (
              <Tab
                key={index}
                label={label}
                {...a11yProps(index)}
                sx={{
                  "&.Mui-selected": {
                    color: "#02496F",
                    fontWeight: "bold",
                  },
                  "&:hover": {
                    color: "#23436d",
                    fontWeight: "bold",
                  },
                }}
              />
            ))}
          </Tabs>

          <div className="w-full">
            {value === 0 && coursesData && (
              <>
                {setshowError && (
                  <div class="space-y-2 w-full p-4">
                    <div
                      role="alert"
                      className="bg-blue-100 dark:bg-blue-900 border-l-4 border-[#23436d] dark:border-blue-700 text-[#23436d] dark:text-blue-100 p-2 !rounded-lg flex items-center transition duration-300 ease-in-out hover:bg-blue-200 dark:hover:bg-blue-800 transform hover:scale-105 animate-bounce "
                    >
                      {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                      <svg
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="h-5 w-5 flex-shrink-0 mr-2 text-blue-600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 16h-1v-4h1m0-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          strokeWidth="2"
                          strokeLinejoin="round"
                          strokeLinecap="round"
                        />
                      </svg>
                      <p class="text-lg font-semibold">
                        Please select atleast three courses to create a bundle
                      </p>
                    </div>
                  </div>
                )}
                <h1 className="text-black font-bold text-[25px] mt-5 mb-5">
                  All Courses
                </h1>
                <MaterialReactTable
                  key={tableKey}
                  columns={CourseColumns}
                  data={coursesData}
                  initialState={{ density: "compact" }}
                  enableRowSelection={enableRowSelection}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  getRowId={(originalRow) => originalRow.id}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: "#e1ecf0",
                      color: "#23436d",
                      "& .MuiSvgIcon-root": {
                        color: "#23436d",
                      },
                      "& .MuiButtonBase-root": {
                        color: "#23436d",
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "12px",
                      overflow: "hidden",
                    },
                  }}
                  muiTablePaginationProps={{
                    sx: {
                      backgroundColor: "#e1ecf0",
                      color: "#23436d",
                      "& .MuiSvgIcon-root": {
                        color: "#23436d",
                      },
                      "& .MuiButtonBase-root": {
                        color: "#23436d",
                      },
                    },
                  }}
                />
              </>
            )}

            {value === 1 && bundlesData && (
              <>
                <h1 className="text-black font-bold text-[25px] mt-5 mb-5">
                  All Bundles
                </h1>
                <MaterialReactTable
                  columns={BundleColumns}
                  data={bundlesData}
                  initialState={{ density: "compact" }}
                  muiTopToolbarProps={{
                    sx: {
                      backgroundColor: "#e1ecf0",
                      color: "#23436d",
                      "& .MuiSvgIcon-root": {
                        color: "#23436d",
                      },
                      "& .MuiButtonBase-root": {
                        color: "#23436d",
                      },
                    },
                  }}
                  muiTablePaperProps={{
                    sx: {
                      borderRadius: "12px",
                      overflow: "hidden",
                    },
                  }}
                  muiTablePaginationProps={{
                    sx: {
                      backgroundColor: "#e1ecf0",
                      color: "#23436d",
                      "& .MuiSvgIcon-root": {
                        color: "#23436d",
                      },
                      "& .MuiButtonBase-root": {
                        color: "#23436d",
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>

        <Dialog
          open={createCourseModalOpen}
          onClose={handleCreateCourseModal}
          fullWidth
        >
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Course
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="title"
              name="title"
              label="Course Title"
              type="text"
              fullWidth
              variant="standard"
              value={createCourseForm.title}
              onChange={(e) =>
                setCreateCourseForm({
                  ...createCourseForm,
                  title: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="description"
              name="description"
              label="Course Description"
              type="text"
              fullWidth
              variant="standard"
              value={createCourseForm.description}
              onChange={(e) =>
                setCreateCourseForm({
                  ...createCourseForm,
                  description: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="priceperseat"
              name="priceperseat"
              label="$ / Seat"
              type="number"
              fullWidth
              variant="standard"
              value={createCourseForm.priceperseat}
              onChange={(e) =>
                setCreateCourseForm({
                  ...createCourseForm,
                  priceperseat: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: "16px",
              display: "flex",

              // justifyContent: "space-between", // Space the buttons apart for a better UI
            }}
          >
            <Button
              onClick={handleCreateCourseModal}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              onClick={handleCreateCourse}
              type="submit"
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#02496F",
                "&:hover": {
                  backgroundColor: "#023A5A",
                },
              }}
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
          {/* <DialogActions>
						<Button onClick={handleCreateCourseModal}>Cancel</Button>
						<Button onClick={handleCreateCourse}>Add</Button>
					</DialogActions> */}
        </Dialog>
        <Dialog
          open={createCourseBundleModalOpen}
          onClose={handleCreateCourseBundleModal}
          fullWidth
        >
          <DialogTitle
            sx={{
              marginBottom: "20px",
              backgroundColor: "#23436d",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Add Bundle
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="title"
              name="title"
              label="Bundle Title"
              type="text"
              fullWidth
              variant="standard"
              value={createCourseBundleForm.title}
              onChange={(e) =>
                setCreateCourseBundleForm({
                  ...createCourseBundleForm,
                  title: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="description"
              name="description"
              label="Bundle Description"
              type="text"
              fullWidth
              variant="standard"
              value={createCourseBundleForm.description}
              onChange={(e) =>
                setCreateCourseBundleForm({
                  ...createCourseBundleForm,
                  description: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="priceperseat"
              name="priceperseat"
              label="$ / Seat"
              type="number"
              fullWidth
              variant="standard"
              value={createCourseBundleForm.priceperseat}
              onChange={(e) =>
                setCreateCourseBundleForm({
                  ...createCourseBundleForm,
                  priceperseat: e.target.value,
                })
              }
            />
          </DialogContent>

          <DialogActions
            sx={{
              padding: "16px",
              display: "flex",

              // justifyContent: "space-between", // Space the buttons apart for a better UI
            }}
          >
            <Button
              onClick={handleCreateCourseBundleModal}
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#d32f2f",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              variant="contained"
            >
              Cancel
            </Button>

            <Button
              onClick={handleCreateCourseBundle}
              type="submit"
              sx={{
                width: "84px",
                color: "white",
                backgroundColor: "#02496F",
                "&:hover": {
                  backgroundColor: "#023A5A",
                },
              }}
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
          {/* <DialogActions>
						<Button onClick={handleCreateCourseBundleModal}>Cancel</Button>
						<Button onClick={handleCreateCourseBundle}>Add</Button>
					</DialogActions> */}
        </Dialog>
      </div>
    </>
  );
};

export default UploadCourse;
