import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import courseicon from "../../../src/assets/course.png";
import { Link } from "react-router-dom";
import { BundleCard } from "../components/BundleCard";
import { Box, Chip, Grid, Tab, Tabs, Typography } from "@mui/material"; // Assuming you're using MUI
import {
  School as SchoolIcon,
  Collections as CollectionsIcon,
} from "@mui/icons-material";
import { ShoppingCart as ShoppingCartIcon } from "@mui/icons-material";
import { Loader } from "../../utils/Loader";
import { FaCartPlus } from "react-icons/fa";
import Heading from "../../components/ui";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const BuyNewLicense = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [bundlesData, setBundlesData] = useState([]);
  const [courseDiscounts, setCourseDiscounts] = useState([]);
  const [bundleDiscounts, setBundleDiscounts] = useState([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getAllCourses = async () => {
    try {
      const result = await axiosInstance.get("/courses/all");
      const courses = result.data.data;
      setLoading(false);
      setCoursesData(courses);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllBundles = async () => {
    try {
      const result = await axiosInstance.get("/courses/allbundles");
      const bundles = result.data.data;
      setBundlesData(bundles);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDiscounts = async () => {
    try {
      const result = await axiosInstance.get("/discounts/get"); // Adjust endpoint as necessary
      const discounts = result.data.data;

      // Separate discounts based on resource type
      const courses = discounts.filter((d) => d.resource_type === "course");
      const bundles = discounts.filter((d) => d.resource_type === "bundle");

      setCourseDiscounts(courses);
      setBundleDiscounts(bundles);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCourses();
    getAllBundles();
    getDiscounts(); // Fetch discounts on component mount
  }, []);
  return (
    <>
      <div className="w-full flex flex-col  justify-start items-center">
        <div className="w-[100%] flex flex-col gap-1 justify-center items-center">
          <div className="w-full flex flex-col justify-center items-start">
            <Heading text="Shop" />
          </div>

          {/* <div className="w-full grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 justify-center gap-2 items-center flex-wrap">
						{coursesData &&
							coursesData.map((course, index) => (
								<CourseCard
									key={index}
									data={course}
									discounts={courseDiscounts}
								/>
							))}
					</div>

					<div className="w-full flex flex-col justify-center items-start">
						<h1 className="text-[#23436d] text-start font-bold text-[30px] mt-5 mb-5">
							Bundles
						</h1>
					</div>
					<div className="w-full grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 justify-center gap-2 items-center flex-wrap mt-8">
						{bundlesData &&
							bundlesData.map((bundle, index) => (
								<BundleCard
									key={index}
									data={bundle}
									discounts={bundleDiscounts}
								/>
							))}
					</div> */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="courses and bundles tabs"
                variant={"standard"}
                // centered={!isMobile}
              >
                <Tab
                  label="Courses"
                  icon={<SchoolIcon />}
                  iconPosition="start"
                  sx={{
                    fontWeight: "bold",
                    // color: theme.palette.primary.main,
                    // '&.Mui-selected': {
                    //   color: theme.palette.primary.dark,
                    // }
                  }}
                />
                <Tab
                  label="Bundles"
                  icon={<CollectionsIcon />}
                  iconPosition="start"
                  sx={{
                    fontWeight: "bold",
                    // color: theme.palette.primary.main,
                    // '&.Mui-selected': {
                    //   color: theme.palette.primary.dark,
                    // }
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {loading && <Loader />}
              {!loading && (
                <>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ fontWeight: "bold", color: "#02496F" }}
                  >
                    Courses
                  </Typography>
                  <Grid container spacing={2}>
                    {coursesData.map((course, index) => (
                      <Grid
                        item
                        xs={12} // 1 card for extra small screens (below 600px)
                        sm={12} // 1 card for small screens (600px - 800px)
                        md={6} // 2 cards for medium screens (800px - 1200px)
                        lg={4} // 3 cards for large screens (1200px - 1700px)
                        xl={3} // 4 cards for extra-large screens (1700px and above)
                        key={index}
                      >
                        <Link
                          to={`/dashboard/course_details?id=${course.id} &admin=true `}
                        >
                          <CourseCard
                            data={course}
                            discounts={courseDiscounts}
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  sx={{ fontWeight: "bold", color: "#02496F" }}
                >
                  Bundles
                </Typography>
                <Grid container spacing={2}>
                  {bundlesData.map((bundle, index) => (
                    <Grid
                      item
                      xs={12} // 1 card for extra small screens (below 600px)
                      sm={12} // 1 card for small screens (600px - 800px)
                      md={6} // 2 cards for medium screens (800px - 1200px)
                      lg={4} // 3 cards for large screens (1200px - 1700px)
                      xl={3} // 4 cards for extra-large screens (1700px and above)
                      key={index}
                    >
                      <Link
                        to={`/dashboard/view_bundles?id=${bundle.bundleId}&admin=true`}
                      >
                        <BundleCard data={bundle} discounts={bundleDiscounts} />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </>
            </TabPanel>
          </Box>

          {coursesData.length === 0 && !loading && (
            <h2 className="text-black text-center font-bold text-1xl">
              No Courses Available!
            </h2>
          )}
        </div>
      </div>
    </>
  );
};

export const CourseCard = ({ data, discounts }) => {
  // Find applicable discount for the course
  const discount = discounts.find(
    (d) => d.resource_Id === data.id && d.enable === "true"
  );

  const getOriginalPrice = () => parseFloat(data.priceperseat) || 0;
  const originalPrice = getOriginalPrice();
  const discountedPrice = discount
    ? originalPrice * (1 - (parseFloat(discount.percentage) || 0) / 100)
    : originalPrice;

  const formatPrice = (price) => {
    return typeof price === "number" ? price.toFixed(2) : "0.00";
  };

  const truncateDescription = (description, maxLength) => {
    return description.length > maxLength
      ? description.slice(0, maxLength) + "..."
      : description;
  };

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow flex flex-col h-full">
      {/* Course Icon */}
      <img
        className="rounded-t-lg no-round w-full h-[200px] object-cover"
        src={courseicon}
        alt="Course Icon"
      />

      {/* Course Info */}
      <div className="p-5 flex-1 flex flex-col justify-between">
        <div className=" mb-2">
          <Typography
            className="truncate"
            style={{
              maxHeight: "2.6em",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="h5"
            component="h2"
            gutterBottom
          >
            {data.title}
          </Typography>

          {/* Truncated Description */}
          <Typography
            style={{
              maxHeight: "4.5em", // Allows for 3 lines of text
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              fontSize: "18px",
            }}
            variant="body2"
            color="textSecondary"
            className="mb-4"
          >
            {truncateDescription(data.description, 100)}
          </Typography>
          {/* {discount && (
						<Typography variant="h6" color="error" className="mb-4 mt-4">
							{discount.percentage}% off
						</Typography>
					)} */}
          {discount && (
            <Chip
              label={`${discount.percentage}% OFF`}
              color="error"
              size="medium"
              sx={{ mb: 1, mt: 1 }}
            />
          )}
        </div>

        {/* Add to Cart Button */}
        <div className="w-full flex justify-between items-end">
          <div className=" flex items-end">
            {discount ? (
              <>
                <div className="flex flex-col">
                  <span
                    className=" text-md"
                    style={{
                      textDecoration: "line-through",
                      color: "gray",
                      marginRight: "10px",
                    }}
                  >
                    ${formatPrice(originalPrice)}
                  </span>
                  <span className=" text-3xl " style={{ color: "green" }}>
                    ${formatPrice(discountedPrice)}
                  </span>
                </div>
              </>
            ) : (
              <p className=" text-3xl text-[#02496F]">
                ${formatPrice(originalPrice)}
              </p>
            )}{" "}
            <span className={`ml-1 text-sm ${discount ? "pb-0" : "pb-1"}`}>
              / seat
            </span>
          </div>
          <div>
            <Link
              className=" cursor-pointer"
              to={`/dashboard/mycart?course_id=${data.id}`}
            >
              <FaCartPlus className=" text-3xl no text-[#02496F] cursor-pointer animate-bounce hover:animate-none " />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuyNewLicense;
