import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { Fab, ThemeProvider, createTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
});

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 opacity-75 z-50">
      <div className="w-[50%] bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
        <div className="bg-gray-600 w-[45%] h-2.5 rounded-full dark:bg-gray-300 animate-move"></div>
      </div>
    </div>
  );
};

const WebLayout = () => {
  const { currentUser, loading, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  if (loading) {
    return <Loader />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return currentUser && userRole === "admin" ? (
    <Navigate to="/dashboard" />
  ) : currentUser && userRole === "contributor" ? (
    <Navigate to="/contrib_dashboard" />
  ) : currentUser && userRole === "groupleader" ? (
    <Navigate to="/groupleader_dashboard" />
  ) : currentUser && userRole === "subscriber" ? (
    <Navigate to="/subscriber_dashboard" />
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <div className="no" style={{ position: "relative", minHeight: "100vh" }}>
          {/* Conditionally show the button if the current route is not "/" */}
          {location.pathname !== "/" && (
            <Fab
              aria-label="go back"
              style={{
                color: "white",
                backgroundColor: "#299aa1",
                position: "fixed",
                top: "2rem",
                left: "2rem",
                padding: "1rem",
                zIndex: 1000,
              }}
              onClick={handleGoBack}
            >
              <ArrowBack />
            </Fab>
          )}
          <Outlet />
        </div>
      </ThemeProvider>
    </>
  );
};

export default WebLayout;
