import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  StepConnector,
  CircularProgress,
  TextField,
  Fab,
} from "@mui/material";
import {
  Person,
  Group,
  Subscriptions,
  Key,
  Fingerprint,
  Check,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import axiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../AuthContext";

const roles = [
  { value: "contributor", label: "Contributor", icon: <Person /> },
  { value: "groupleader", label: "Group Leader", icon: <Group /> },
  { value: "subscriber", label: "Subscriber", icon: <Subscriptions /> },
];

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [signinType, setSigninType] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [resetPassword, setResetPassword] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showResetPassword, setshowResetPassword] = useState(false);
  const [otpExpiry, setOtpExpiry] = useState(null);
  const [resend, setResend] = useState(false);

  const formatExpiryDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  const { login, updateUserRoles } = useAuth();
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post("/v1/auth/get_user_role", {
        email,
      });
      const data = response.data;

      if (!data.success && data.message === "User not found") {
        setError("No account found with this email");
        return;
      }

      const roles = data.user.roles;
      updateUserRoles(roles);
      if (roles.length === 1) {
        const { role, signin_type } = roles[0];
        setUserRole(role);
        setSigninType(signin_type);
        setShowBack(true);

        if (signin_type === "pwd_less") {
          setShowBack(true);
          handleSendOtp(role);
        }
      } else if (roles.length > 1) {
        setShowBack(true);
        setUserRole(roles);
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let endpoint = "";
      let dashboardPath = "";

      switch (userRole) {
        case "admin":
          endpoint = "/v1/auth/admin_login";
          dashboardPath = "/dashboard";
          break;
        case "contributor":
          endpoint = "/v1/auth/contrib_login";
          dashboardPath = "/contrib_dashboard";
          break;
        case "groupleader":
          endpoint = "/v1/auth/groupleader_login";
          dashboardPath = "/groupleader_dashboard";
          break;
        case "subscriber":
          endpoint = "/v1/auth/subscriber_login";
          dashboardPath = "/subscriber_dashboard";
          break;
        default:
          throw new Error("Invalid user type");
      }

      const response = await axiosInstance.post(endpoint, {
        email,
        password,
      });

      const { user } = response.data;
      login(user);
      navigate(dashboardPath);
    } catch (error) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  const handleSendOtp = async (role) => {
    try {
      const endpoint =
        role === "groupleader"
          ? "/v1/auth/pwdless/groupleader_loginotp"
          : "/v1/auth/pwdless/subscriber_loginotp";

      const response = await axiosInstance.post(endpoint, { email });

      if (response.status === 200 && response.data.success === true) {
        setIsOtpSent(true);
        setMessage("OTP sent successfully!");
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/otp/verify-otp", {
        email,
        otpCode: otp,
        userType: userRole,
      });

      if (response.status === 200 && response.data.success) {
        setLoading(false);
        login(response.data?.user);
        navigate(`/${userRole}_dashboard`);
      } else {
        setLoading(false);
        setMessage(response.data.message || "Invalid OTP or OTP expired.");
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error verifying OTP. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError("Please enter your email.");
      return;
    }
    setLoading(true);
    setError("");
    setShowBack(true);
    setMessage("");
    let user_role = "";
    try {
      const response = await axiosInstance.post("/v1/auth/get_user_role", {
        email,
      });
      const data = response.data;
      console.log("data", data);
      const { role, signin_type } = data.user.roles[0];

      if (signin_type !== "pwd") {
        setError("You do not have password account.");
        setLoading(false);
        return;
      }
      setUserRole(role);
      user_role = role;
      setResetPassword(true);

      if (!data.success && data.message === "User not found") {
        setError("No account found with this email");
        setLoading(false);
        return;
      }
    } catch (error) {
      setError("Something went wrong. Please try again.");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post("/v1/auth/send_reset_otp", {
        email,
        userType: user_role,
        resend,
      });

      if (response.data.success) {
        setMessage("Reset OTP sent successfully!");
        setIsOtpSent(true);
      } else if (!response.data.success && response.data.otpData) {
        // OTP already exists case
        const expiryDate = new Date(response.data.otpData.date);
        expiryDate.setDate(expiryDate.getDate() + 2);
        setOtpExpiry(expiryDate);
        setResend(true);
        setIsOtpSent(true);
        setMessage(
          `An OTP has already been sent. Valid until ${formatExpiryDate(
            expiryDate
          )}`
        );
      }
    } catch (error) {
      setIsOtpSent(true);
      setError("Error sending reset OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetVerifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/otp/verify-otp", {
        email,
        otpCode: otp,
        userType: userRole,
      });

      if (response.status === 200 && response.data.success) {
        setResetPassword(false);
        setshowResetPassword(true);
        setResend(false);
        setLoading(false);
        setMessage("");
      } else {
        setMessage(response.data.message || "Invalid OTP or OTP expired.");
        setLoading(false);
      }
    } catch (error) {
      setMessage("Error verifying OTP. Please try again.");
      setLoading(false);
    }
  };

  const handleConfrimPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/v1/auth/reset_password", {
        email,
        newPassword,
        user_role: userRole,
      });

      if (response.data.success) {
        setMessage(
          "Password reset successfully! You can now sign in with your new password."
        );
        setNewPassword("");
        setshowResetPassword(false);

        setConfirmPassword("");
        // setTimeout(() => {
        //   setUserRole(null);
        // }, 3000);
      } else {
        setError(response.data.message || "Failed to reset password");
      }
    } catch (error) {
      setError("Error resetting password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderLoginForm = () => {
    if (Array.isArray(userRole)) {
      return (
        <Box sx={{ width: "100%", gap: 2 }}>
          <Typography variant="h6">Select a role to continue:</Typography>
          {userRole.map((roleObj, index) => (
            <button
              key={index}
              onClick={() => {
                setUserRole(roleObj.role);
                setSigninType(roleObj.signin_type);
                if (roleObj.signin_type === "pwd_less") {
                  handleSendOtp(roleObj.role);
                }
              }}
              className="bg-[#299aa1] px-6 text-md text-white my-4 py-3 rounded-full mr-2"
            >
              {roleObj.role}
            </button>
          ))}
        </Box>
      );
    }
    if (!userRole) {
      return (
        <Box
          component="form"
          onSubmit={handleEmailSubmit}
          sx={{ width: "100%", gap: 2 }}
        >
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError("");
            }}
            error={!!error}
            helperText={error}
            disabled={loading}
          />
          <button
            disabled={loading}
            type="submit"
            className="bg-[#299aa1] px-6 text-lg hover:bg-[#23436d] transition-colors duration-200 ease-in text-white w-full my-4 py-3 rounded-full flex justify-center items-center"
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Continue"
            )}
          </button>
          <div className="flex justify-end">
            <Typography
              variant="body2"
              onClick={handleResetPassword}
              sx={{ cursor: "pointer", color: "#23436d", mt: 1 }}
            >
              Reset Your Password
            </Typography>
          </div>
        </Box>
      );
    }

    if (signinType === "pwd_less") {
      return (
        <Box sx={{ width: "100%", gap: 2 }}>
          {!isOtpSent ? (
            <Typography>Sending OTP to {email}...</Typography>
          ) : (
            <>
              <TextField
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
              />
              <button
                disabled={loading}
                onClick={handleVerifyOtp}
                className="bg-[#299aa1] px-6 text-lg hover:bg-[#23436d] transition-colors duration-200 ease-in text-white w-full my-4 py-3 rounded-full flex justify-center items-center"
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Verify OTP"
                )}
              </button>
              {/* <Button
                fullWidth
                variant="contained"
                onClick={handleVerifyOtp}
                sx={{
                  mt: 2,
                  backgroundColor: "#23436d",
                  "&:hover": { backgroundColor: "#00314D" },
                }}
              >
                Verify OTP
              </Button> */}
            </>
          )}
          {message && (
            <Typography
              color={message.includes("successfully") ? "success" : "error"}
              sx={{ mt: 2 }}
            >
              {message}
            </Typography>
          )}
        </Box>
      );
    }
    if (resetPassword) {
      return (
        <Box sx={{ width: "100%", gap: 2 }}>
          {!isOtpSent ? (
            <Typography>Sending OTP to {email}...</Typography>
          ) : (
            <>
              <TextField
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
              />
              <button
                className="bg-[#299aa1] px-6 text-lg hover:bg-[#23436d] transition-colors duration-200 ease-in text-white w-full my-4 py-3 rounded-full flex justify-center items-center"
                onClick={handleResetVerifyOtp}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Verify OTP"
                )}
              </button>
            </>
          )}
          {message && (
            <Typography
              color={message.includes("successfully") ? "success" : "error"}
              sx={{ mt: 2 }}
            >
              {message}
            </Typography>
          )}
          {resend && (
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleResetPassword}
              sx={{
                mt: 2,
                backgroundColor: "#23436d",
                "&:hover": { backgroundColor: "#00314D" },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Send otp again"
              )}
            </Button>
          )}
        </Box>
      );
    }
    if (showResetPassword) {
      return (
        <Box
          component="form"
          onSubmit={handleConfrimPassword}
          sx={{ width: "100%", gap: 2 }}
        >
          <TextField
            required
            fullWidth
            id="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            error={!!error}
            margin="normal"
          />
          <TextField
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!error}
            helperText={error}
            margin="normal"
          />
          <button
            disabled={loading}
            type="submit"
            className="bg-[#299aa1] px-6 text-lg hover:bg-[#23436d] transition-colors duration-200 ease-in text-white w-full my-4 py-3 rounded-full flex justify-center items-center"
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Reset Password"
            )}
          </button>
          {message && (
            <Typography color="success" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </Box>
      );
    }

    return (
      <Box
        component="form"
        onSubmit={handlePasswordLogin}
        sx={{ width: "100%", gap: 2 }}
      >
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          value={email}
          disabled
          margin="normal"
        />
        <TextField
          required
          fullWidth
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error}
          helperText={error}
          margin="normal"
        />
        <button
          disabled={loading}
          type="submit"
          style={{
            borderRadius: "10px !important",
          }}
          className="bg-[#299aa1] px-6 text-lg text-white my-4 py-3  w-full flex justify-center items-center "
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Sign In"
          )}
        </button>
        {/* <Button
          type="submit"
          variant="contained"
          disabled={loading}
          sx={{
            alignContent: "center",
            mt: 2,
            backgroundColor: "#299aa1",
            "&:hover": { backgroundColor: "#248c94" }, // Hover color closer to #299aa1
            height: 48,
            borderRadius: "24px", // Fully rounded
            alignItems: "flex-start", // Align items to start
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Sign In"
          )}
        </Button> */}
      </Box>
    );
  };
  const handleGoBack = () => {
    setUserRole(null);
    navigate(-1);
  };

  return (
    <div className="bg-[#23436d] flex items-center justify-center min-h-screen no">
      {showBack && (
        <div style={{ position: "relative", minHeight: "100vh" }}>
          <Fab
            aria-label="go back"
            style={{
              color: "white",
              backgroundColor: "#299aa1",
              position: "fixed",
              top: "2rem",
              left: "2rem",
              zIndex: 1000,
            }}
            onClick={handleGoBack}
          >
            <ArrowBack />
          </Fab>
        </div>
      )}
      <Container component="main" maxWidth="sm">
        {/* <div className="mb-6 text-center">
          <h2 className="font-bold text-4xl">
            LMS{" "}
            <span className="bg-[#23436d] text-white px-2 rounded-md">
              Login
            </span>
          </h2>
        </div> */}
        <Paper
          elevation={3}
          sx={{
            p: 4,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="w-full mb-4">
            {!userRole ? (
              <div className="flex items-center justify-between">
                <div className="flex gap-1 my-4 justify-center items-center">
                  <h2 className="font-bold text-3xl   justify-center flex ">
                    LMS{"    "}
                  </h2>
                  <span className="bg-[#23436d] text-xl text-center text-white  px-3 py-1 rounded-md">
                    Login
                  </span>
                </div>
                <Link to={"/partner_login"}>
                  <h2 className="text-xl items-end text-[#299aa1] hover:text-[#23436d]">
                    Partner Login
                    <ArrowForward className="ml-2" />
                  </h2>
                </Link>
              </div>
            ) : (
              <div className="flex w-full  justify-between ">
                <h2 className="text-3xl text-[#23436d]">Sign in</h2>
                <Link to={"/partner_login"}>
                  <h2 className="text-xl items-end text-[#299aa1]">
                    Partner Portal
                  </h2>
                </Link>
              </div>
            )}
          </div>
          {renderLoginForm()}
          <h2 className="text-lg text-black my-4 ">
            <a
              href="/terms-of-services"
              className="underline text-[#23436d]"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Services
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              className="text-[#23436d] underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </h2>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;
