import React from "react";
import Heading from "../../components/ui";

function Knowledge() {
  return (
    <>
      <Heading text="Knowledge Base" />
      <div className="flex justify-center mt-10 animate-bounce items-center">
        <div class="relative w-full  flex flex-wrap items-center justify-center py-3 pl-4 pr-14 rounded-lg text-base font-medium [transition:all_0.5s_ease] border-solid border border-[#f85149] text-[#b22b2b] [&amp;_svg]:text-[#b22b2b] group bg-[linear-gradient(#f851491a,#f851491a)]">
          <p class="flex flex-row items-center mr-auto gap-x-2">
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="28"
              width="28"
              class="h-7 w-7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
              <path d="M12 9v4"></path>
              <path d="M12 17h.01"></path>
            </svg>
            Coming Soon
          </p>
        </div>
      </div>
    </>
  );
}

export default Knowledge;
