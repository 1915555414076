import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setloading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    const storedUserRoles = localStorage.getItem("userRoles");
    if (storedToken && storedUser) {
      let user = JSON.parse(storedUser);
      setCurrentUser(user);
      setUserRole(user.role);
    }

    if (storedUserRoles) {
      setUserRoles(JSON.parse(storedUserRoles)); // Retrieve `userRoles` from localStorage
    }
    setloading(false);
  }, []);

  const login = (user) => {
    localStorage.setItem("token", user.token);
    localStorage.setItem("user", JSON.stringify(user.user));
    setCurrentUser(user.user);
    setUserRole(user.user.role);
    setloading(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userRoles"); // Clear userRoles on logout
    localStorage.removeItem("user");
    setCurrentUser(null);
    setUserRole(null);
  };

  const updateUserRoles = (rolesArray) => {
    setUserRoles(rolesArray); // Set roles array in state
    localStorage.setItem("userRoles", JSON.stringify(rolesArray)); // Store in localStorage
  };

  const selectUserRole = (role) => {
    const selectedRole = userRoles.find((r) => r.role === role);
    if (selectedRole) {
      const updatedUser = {
        ...currentUser,
        role: selectedRole.role,
        id: selectedRole.id,
      };
      setCurrentUser(updatedUser);
      setUserRole(selectedRole.role);
      localStorage.setItem("user", JSON.stringify(updatedUser));
    }
  };
  return (
    <AuthContext.Provider
      value={{
        loading,
        currentUser,
        userRole,
        login,
        logout,
        selectUserRole,
        updateUserRoles,
        userRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
