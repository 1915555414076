import React from "react";

const Heading = ({ text, extraClasses = "" }) => {
  return (
    <h1
      className={`text-[#23436d] font-bold text-[40px] mt-5 mb-5 ${extraClasses}`}
    >
      {text}
    </h1>
  );
};

export default Heading;
